import Header from "../global/Header";
import "../activity/activity.css";
import { useState, useEffect, useCallback } from "react";
import { fetchSwapActivity } from "../global/axios";
import { Spinner } from "react-bootstrap";

const Activity = () => {
    const [activityStats, setActivityStats] = useState(null);

    const fetchData = useCallback(async () => {
        try {
          
          const data = await fetchSwapActivity();
          setActivityStats(data);
        } catch (error) {
          console.error("Error fetching activity statistics:", error);
        }
      }, []);
    
      useEffect(() => {
        fetchData();
      }, [fetchData]);

      if (activityStats === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }

    return (
        <div>
        <Header title={"Swap Activity"}/> 
        <div className="boxes">
                <div className="box" style={{ height: '100%', background: '#B9E805', borderRadius: 4, border: '2px #A3C913 solid' }}>
                    <div className="boxTitle">{activityStats.totalSuccessful}</div>
                    <div className="boxSubtitle">Successful Swaps</div>
                </div>
                <div className="box" style={{ height: '100%', background: '#FFCF23', borderRadius: 4, border: '2px #E2B820 solid' }} >
                    <div className="boxTitle">{activityStats.totalPending}</div>
                    <div className="boxSubtitle">Pending Swaps</div>
                </div>
                <div className="box" style={{ height: '100%', background: '#D43A2F', borderRadius: 4, border: '2px #A71F1F solid' }}>
                    <div className="boxTitle" style={{ color: "white" }}>{activityStats.totalFailed}</div>
                    <div className="boxSubtitle" style={{ color: "white" }}>Failed Swaps</div>
                </div>
            </div>
        </div>
);
};

export default Activity;