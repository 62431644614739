import axios from 'axios';
import { setAuthToken } from './axiosconfg';




const loginUser = async (email, password) => {
    try {
        const response = await axios.post(
            'login',
            {
                email: email,
                password: password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        const token = response.data.access_token;
        setAuthToken(token);
        localStorage.setItem("AdminID",response.data.id);
        localStorage.setItem("AdminName", response.data.name);

        return token;
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};
const Logoutuser = async () =>{
    const res = await axios.post('logout');
    return res;

};

const Forgetpassuser = async (payload) =>{
    const res = await axios.post('forget-password',payload);
    return res;

};
const fetchUserStatistics = async () => {
    
    try {
        const response = await axios.get('user-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching user statistics:', error);
        throw error;
    }
};

const fetchUserdata = async ({pageNumber = 1, size = 1}) => {
    try {
        const response = await axios.get(`users?sortByJoinedAt=true&page=${pageNumber}&size=${size}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
};
const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`user?userId=${userId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchsupportmessage = async (userId) => {
    try {
        
        const response = await axios.get(`support-messages?userId=${userId}`);
       // const response = await axios.get(`support-messages?userId=6529ccd9b71bf5480dbcf562`);

        return response.data;
    } catch (error) {
        console.error('Error fetching user support massge:', error);
        throw error;
    }
};
const fetchUserproduct = async (userId) => {
    try {
       
        const response = await axios.get(`user-items?userId=${userId}`);
        return response;
    } catch (error) {
        console.error('Error fetching user support massge:', error);
        throw error;
    }
};

const handleStauts = async (id,statuss) =>{
    try{
        const capitalizedStatus = statuss.toUpperCase();
        const res = await axios.patch(`update-account-status`,
        {
            "userId": id,
            "status": capitalizedStatus
        })
        return res;
    }catch(error){
        console.error('Error fetching user account-status:', error);
        throw error;
    }
};

const fetchSwapActivity = async () => {
    try {
       
        const response = await axios.get('swap-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching swap statistics:', error);
        throw error;
    }
};

const fetchproductStat = async () => {
    try {
       
        const response = await axios.get('items-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching product statistics:', error);
        throw error;
    }
};

const fetchBehaviourStat = async () => {
    try {
       
        const response = await axios.get('user-behavior-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching behaviour statistics:', error);
        throw error;
    }
};

 const createHandleOpenMessgae = async (IssueId) =>{
     try{
         const res = await axios.patch(`change-support-status?issueId=${IssueId}`)
         return res;
     }catch(error){
         console.error('Error fetching user support massge:', error);
         throw error;
     }
 };

 const fetchFullUserStat = async () => {
    try {
       
        const response = await axios.get('full-users-statistics',);
        return response.data;
    } catch (error) {
        console.error('Error fetching full-users statistics:', error);
        throw error;
    }
};

const handleBulkAction = async (status,items) =>{
    try{
        const res = await axios.patch(`item-bulk-action`, {
            action: status,
            items: items,
          });
        return res;
    }catch(error){
        console.error('Error fetching bulk action :', error);
        throw error;
    }
};
const fetchproductDetails = async (productId) => {
    try {
       
        const response = await axios.get(`item?itemId=${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product detail:', error);
        throw error;
    }
};
const createLocation = async (payload) => {
    try {
      const response = await axios.post('location', payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const UpdateLocation = async (id , payload ) => {
    try {
      const response = await axios.patch(`location?locationId=${id}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const DeleteLocation = async (locationId) => {
    try {
      const response = await axios.delete(`location?locationId=${locationId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const fetchLocation = async () => {
    try {
       
        const response = await axios.get('locations',);
        return response.data;
    } catch (error) {
        console.error('Error fetching locations data:', error);
        throw error;
    }
};

const fetchInterest = async () => {
    try {
       
        const response = await axios.get('interests',);
        return response.data;
    } catch (error) {
        console.error('Error fetching interest data:', error);
        throw error;
    }
};
const createIntrest = async (payload) => {
    try {
      const response = await axios.post('interest',{
        "name": payload
      });
      return response;
    } catch (error) {
      throw error;
    }
  }; 
  const Updateinterst = async (id , payload ) => {
    try {
      const response = await axios.patch(`interest?interestId=${id}`,{
        "name": payload
      },{headers: {
        'Content-Type': 'application/json'},
    });
      return response;
    } catch (error) {
      throw error;
    }
  };
const fetchAdmin = async () => {
    try {
       
        const response = await axios.get('admins',);
        return response.data;
    } catch (error) {
        console.error('Error fetching admin data:', error);
        throw error;
    }
};
const createAdmin = async (payload) => {
    try {
      const response = await axios.post('admin',payload);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const DeleteAdmin = async (admintobedel,adminid) => {
    try {
      const response = await axios.delete(`admin?adminIdToBeDeleted=${admintobedel}&adminId=${adminid}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const changePasswordApi = async (payload) => {
    try {
       const adminid =localStorage.getItem("AdminID")
      const response = await axios.post(`change-password?id=${adminid}`,payload);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetchCategories = async () => {
    try {
       
        const response = await axios.get('categories?size=1000');
        return response.data;
    } catch (error) {
        console.error('Error fetching categories data:', error);
        throw error;
    }
}; 
const fetchAllProduct = async ({ fromDate = null, toDate = null, selectedConditions = null, selectedStatus = null, selectedCategory = null, selectedLocation = null, pageNumber = 1, size = 10}) => {
  try {
      let apiUrl = `items?page=${pageNumber}&size=${size}`;

      // Conditionally add parameters if they are provided
      if (fromDate) apiUrl += `&fromDate=${fromDate}`;
      if (toDate) apiUrl += `&toDate=${toDate}`;
      if (selectedConditions) apiUrl += `&itemCondition=${selectedConditions}`;
      if (selectedStatus) apiUrl += `&itemStatus=${selectedStatus}`;
      if (selectedCategory) apiUrl += `&categoryId=${selectedCategory}`;
      if (selectedLocation) apiUrl += `&locationId=${selectedLocation}`;

      const response = await axios.get(apiUrl);
      return response.data;
  } catch (error) {
      throw error;
  }
};
  const updateStautsButtom = async (payload)=>{
    try {
      const response = await axios.patch(`update-item-status`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const updateHiddenStatus = async (itemId)=>{
    try {
      const response = await axios.patch(`item-hidden-status?itemId=${itemId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const updateItemCategories = async ({itemId, mainCategoryId, subCategoryId})=>{
    try {
      const payload = {
        "mainCategoryId": mainCategoryId,
        "subCategoryId": subCategoryId
      };
      console.log('Update Item Categories Payload:', payload);
      const response = await axios.patch(`item?itemId=${itemId}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const updateUserInterests = async ({userId, interests})=>{
    try {
      const payload = {
        "interestIds": [...interests],
      }; 
      console.log('Update User Interests Payload:', payload);
      const response = await axios.patch(`user?userId=${userId}`, payload);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const fetchReports = async () => {
    try {
       
        const response = await axios.get('generatedReports?size=100',);
        return response.data;
    } catch (error) {
        console.error('Error fetching reports data:', error);
        throw error;
    }
};
const GenrateNewReport =async (payload)=>{
  try{
    const res =await axios.post('generateNewReport',payload);
    return res.data;


  }catch(error){
    throw error;
  }
};
const GenrateReport = async (reportId)=>{
  try{
    const res = await axios.get(`generatedReport?reportId=${reportId}`);
    return res.data;
  }
  catch(error){
    throw error;
  }
}
  const HandleEditCategory =async (CategoryId,payload) =>{
    try{
      const res =await axios.patch(`category?categoryId=${CategoryId}`,payload);
      return res;


    }
    catch(error){
      throw error
    }
  };

  const HandleNewImageSubmit = async (payload) =>{
    try{
      const res =await axios.post(`main-category`,payload);
      return res;
    }
    catch(error){
      throw error
    }
  };
  

  const HandleSubNewImageSubmit = async (selectedMainCategory,payload) =>{
    try{
      const res =await axios.post(`sub-category?mainCategoryId=${selectedMainCategory}`,payload);
      return res;
    }
    catch(error){
      throw error
    }
  };

  const DeleteSubCategory = async (subCategoryId) => {
    try {
      const response = await axios.delete(`sub-category?subCategoryId=${subCategoryId}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

export {  loginUser, createHandleOpenMessgae, fetchUserStatistics ,fetchUserdata ,fetchsupportmessage ,fetchUserproduct ,handleStauts,
     fetchSwapActivity, fetchproductStat, fetchBehaviourStat, fetchFullUserStat ,fetchAllProduct,handleBulkAction ,fetchproductDetails ,
     createLocation, fetchLocation, fetchInterest, fetchAdmin,UpdateLocation , DeleteLocation,createIntrest,Updateinterst,createAdmin,DeleteAdmin,
     changePasswordApi, Logoutuser,Forgetpassuser, fetchCategories,updateStautsButtom, fetchReports,GenrateNewReport,GenrateReport ,
     HandleEditCategory,HandleNewImageSubmit,HandleSubNewImageSubmit,DeleteSubCategory,deleteUser, updateHiddenStatus, updateItemCategories, updateUserInterests};