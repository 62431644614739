import Header from "../../global/Header";
import { ProgressBar, Spinner } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { fetchproductStat } from "../../global/axios";
import "../../globalItems/Chart/chart.css";
import Toast from 'react-bootstrap/Toast';
const ProductStatistics = () => {
    const [productStats, setProductStats] = useState(null);
    const [statusToast, setStatusToast] = useState(null);
    const fetchData = useCallback(async () => {
        try {
            
            const data = await fetchproductStat();
            setProductStats(data);
        } catch (error) {
            console.error("Error fetching product statistics:", error);
            setStatusToast({ status: 'error', message: 'Error Fetching Product Statistics' });
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (productStats === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }



    return (
        <div>
            <Header title={"Product Statistics"} />
            <div>
                <div className="boxes">
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2" }} >
                        <div className="boxTitle" style={{ color: "white" }}>{productStats.totalItems}</div>
                        <div className="boxSubtitle" style={{ color: "white" }}>Total No. of Listings</div>
                    </div>
                    <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                        <div className="boxTitle">{productStats.totalActiveItems}</div>
                        <div className="boxSubtitle">Total No. of Active Listings</div>
                    </div>
                    <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                            {productStats.totalExpiredItems}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                            Total No. of Expired Listings
                        </div>
                    </div>
                    <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                        <div className="boxTitle" style={{ color: "white" }}>
                            {productStats.totalSuspendedItems}
                        </div>
                        <div className="boxSubtitle" style={{ color: "white" }}>
                            Total No. of Suspended Listings
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", margin: "0px 20px 0px 20px" }}>
                <div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2", marginBottom: "20px", marginTop: "20px" }} >
                        <div className="boxTitle" style={{ color: "white" }}> {productStats.avgPerUser ? (productStats.avgPerUser).toFixed(2) : 'N/A'} </div>
                        <div className="boxSubtitle" style={{ color: "white", fontSize: "11px" }}>Average No. of Listings Per User</div>
                    </div>
                    <div className="box" style={{ background: "#0047FF", border: "#356AF2", marginTop: "30px" }} >
                        <div className="boxTitle" style={{ color: "white" }}>{productStats.avgPerCategory ? (productStats.avgPerCategory).toFixed(2) : 'N/A'}</div>
                        <div className="boxSubtitle" style={{ color: "white", fontSize: "11px" }}>Average No. of Listings Per Category</div>
                    </div>
                </div>
                <div style={{ height: "240px" }}>
                    <div className="chart" style={{ marginLeft: "0px", width: "300px", marginTop: "18px" }}>
                        <div className="chartTitle" style={{ paddingBottom: "10px" }}>
                            Most Liked Category
                        </div>
                        <div>
                            {productStats.likedCategories.slice(0, 5).map((likedItem) => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="barLabel">{likedItem.categoryName}</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={likedItem.percentage} max="100" />
                                    </div>
                                    <div className="barValue">{likedItem.percentage.toFixed(2)}%</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
        </div>
    );
};

export default ProductStatistics;