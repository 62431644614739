import Header from "../global/Header";
import "../locations/locations.css";
import { useState, useCallback, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { createLocation } from "../global/axios";
import { fetchLocation, UpdateLocation , DeleteLocation} from "../global/axios";
import { Spinner, Pagination } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';

const Locations = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [showOverlay2, setShowOverlay2] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [name, setName] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [Locid, setLocid] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [statusToast, setStatusToast] = useState(null);
    const [file, setFile] = useState(null);
    const [base64Image, setBase64Image] = useState('');
    const [locationimage, setlocationimage] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    function imageToBase64(file, callback) {
        const reader = new FileReader();

        reader.onload = function () {
            const base64String = reader.result.split(',')[1]; // Extract the Base64 string part
            callback(base64String);
        };

        reader.readAsDataURL(file);
    }


    const deleteLocation = async (id) => {
        try {
            await DeleteLocation(id);
        } catch (error) {
            console.error('Error deleting location:', error);
            setStatusToast({ status: 'error', message: 'Error in deleting location' });
        }

    };

    const fetchData = useCallback(async () => {
        try {
            let data = await fetchLocation();
            console.log(data);
            const updatedData = data.data.map(item => ({
                ...item,
                // Replace 'image' with a default URL if it's null
                image: item.image === null ? 'http://localhost:9090/api/swapImages?id=6589d3288ed7b5363cf230ee' : item.image
            }));
            console.log(updatedData);
            setLocationData(updatedData);
        } catch (error) {
            console.error("Error fetching location data:", error);
            setStatusToast({ status: 'error', message: 'Error fetching location data' });
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    let totalPages, currentItems;
    if (locationData) {
        totalPages = Math.ceil(locationData.length / itemsPerPage);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentItems = locationData.slice(indexOfFirstItem, indexOfLastItem);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }

    if (locationData === null || imageToBase64 === null || isLoading) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }




    const handleCreateLocation = async (action, locId) => {
        try {
            setIsLoading(true);
            if ((!address || !city || !country || !latitude || !longitude) && !locId) {
                setStatusToast({ status: 'error', message: 'Address, City, Country, Latitude, and Longitude are required.' });
                setIsLoading(false);
                return;
            }

            if (action === "CREATE") {
                const payload = {
                    address,
                    country,
                    city,
                    name: name || "",
                    latitude,
                    longitude,
                    image: base64Image || "",

                };
                await createLocation(payload);
                setStatusToast({ status: 'success', message: 'Location created successfully!' });
                fetchData();
            } else if (action === "UPDATE" && Locid) {
                //console.log(Locid);
                const payload = {
                    address,
                    country,
                    city,
                    name: name,
                    latitude,
                    longitude,
                };

                if (base64Image) {
                    payload['image'] = base64Image;
                } else {
                    payload['image'] = locationimage;
                }
                await UpdateLocation(Locid, payload);
                setStatusToast({ status: 'success', message: 'Location Updated successfully!' });
                fetchData();
            }
            else if (action === "DELETE" && locId){
                await deleteLocation(locId);
                setStatusToast({ status: 'success', message: 'Location Deleted successfully!' });
                fetchData();
            } else {
            setAddress('');
            setCountry('');
            setCity('');
            setName('');
            setLatitude('');
            setLongitude('');
            setBase64Image('');
            setlocationimage('');
            setLocid(null);
        }
        } catch (error) {
            console.error('Error creating location:', error);
            setStatusToast({ status: 'error', message: 'Error in creating or updating location' });
            if (error.response && error.response.status === 500) {
                setStatusToast({ status: 'error', message: 'Location already exist with the same address, city and country' });
            }
        }
        finally {
            setIsLoading(false); // Reset loading state after operation is complete
        }
    };
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        if (selectedFile) {
            imageToBase64(selectedFile, (base64String) => {
                setBase64Image(base64String);
            });
        }
    };
    const UpdatingLocation = async (location) => {
        setAddress(location.address);
        setCountry(location.country);
        setCity(location.city);
        setName(location.name);
        setLatitude(location.latitude);
        setLongitude(location.longitude);
        setLocid(location.id);
        setlocationimage(location.image);
    };
    
    return (

        <div>
            <Header title={"Locations"} />
            <div>
                <div className="createButtonHeader">
                    <div className="createButton" type="button" onClick={() => setShowOverlay2(true)}>Create</div>
                    {showOverlay2 && (
                        <>
                            <div className="overlayBackground" onClick={() => setShowOverlay2(false)}></div>
                            <div className="overlay">
                                <div className="createOverlay">
                                    <div className="createOverlayTitle">Create A New Location:</div>
                                    <div className="entry">
                                        <div className="entryTitle">Address:</div>
                                        <input className="entryInput" onChange={(e) => setAddress(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Country:</div>
                                        <input className="entryInput" onChange={(e) => setCountry(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">City:</div>
                                        <input className="entryInput" onChange={(e) => setCity(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Name:</div>
                                        <input className="entryInput" onChange={(e) => setName(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Latitude:</div>
                                        <input className="entryInput" onChange={(e) => setLatitude(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Longitude:</div>
                                        <input className="entryInput" onChange={(e) => setLongitude(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Image:</div>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            maxsize={1024 * 1024 * 10}
                                            onChange={handleImageUpload}
                                        />
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                        <div className="submitCreation" type="button" onClick={() => { handleCreateLocation("CREATE"); setShowOverlay2(false) }}> Submit </div>
                                    </div>
                                </div>
                                <CloseIcon className="overlayClose" onClick={() => setShowOverlay2(false)} type="button" />
                            </div>
                        </>
                    )}
                </div>
                <table className="locationTable">
                    <thead className="interestTableHeading">
                        <tr>
                            <th className="interestTableHead2">ID</th>
                            <th className="interestTableHead">Country</th>
                            <th className="interestTableHead">City</th>
                            <th className="interestTableHead">Address</th>
                            <th className="interestTableHead">Image</th>
                            <th className="interestTableHead">Name</th>
                            <th className="interestTableHead">Latitude</th>
                            <th className="interestTableHead">Longitude</th>
                            <th className="interestTableHead"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((location) => (
                            <tr className="interestTableRow" key={location.id}>
                                <td className="interestTableData2">{location.id}</td>
                                <td className="interestTableData">{location.country}</td>
                                <td className="interestTableData">{location.city}</td>
                                <td className="interestTableData">{location.address}</td>
                                <td className="interestTableData"><img style={{ maxHeight: "25px" }} src={location.image} alt="..." /></td>
                                <td className="interestTableData">{location.name}</td>
                                <td className="interestTableData">{location.latitude}</td>
                                <td className="interestTableData">{location.longitude}</td>
                                <td className="interestTableData3"><div className="updateButton" type="button" onClick={() => { setShowOverlay(true); UpdatingLocation(location); }}>Update</div> </td>
                                <td className="interestTableData3"><div className="deleteButton" type="button" onClick={() => {handleCreateLocation('DELETE', location.id) }}>Delete</div> </td>
                                {showOverlay && (
                                    <>
                                        <div className="overlayBackground" onClick={() => setShowOverlay(false)}></div>
                                        <div className="overlay">
                                            <div className="createOverlay">
                                                <div className="createOverlayTitle">Update Location:</div>
                                                <div className="entry">
                                                    <div className="entryTitle">Address:</div>
                                                    <input className="entryInput" value={address} onChange={(e) => setAddress(e.target.value)}></input>
                                                </div>
                                                <div className="entry">
                                                    <div className="entryTitle">Country:</div>
                                                    <input className="entryInput" value={country} onChange={(e) => setCountry(e.target.value)}></input>
                                                </div>
                                                <div className="entry">
                                                    <div className="entryTitle">City:</div>
                                                    <input className="entryInput" value={city} onChange={(e) => setCity(e.target.value)}></input>
                                                </div>
                                                <div className="entry">
                                                    <div className="entryTitle">Name:</div>
                                                    <input className="entryInput" value={name} onChange={(e) => setName(e.target.value)}></input>
                                                </div>
                                                <div className="entry">
                                                    <div className="entryTitle">Latitude:</div>
                                                    <input className="entryInput" value={latitude} onChange={(e) => setLatitude(e.target.value)}></input>
                                                </div>
                                                <div className="entry">
                                                    <div className="entryTitle">Longitude:</div>
                                                    <input className="entryInput" value={longitude} onChange={(e) => setLongitude(e.target.value)}></input>
                                                </div>
                                                <div className="entry">
                                                    <div className="entryTitle">Image:</div>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/png"
                                                        maxsize={1024 * 1024 * 10}
                                                        onChange={handleImageUpload}

                                                    />
                                                </div>
                                                <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                                    <div className="submitCreation" type="button" onClick={() => { handleCreateLocation("UPDATE"); setShowOverlay(false) }}> Submit </div>
                                                </div>
                                            </div>
                                            <CloseIcon className="overlayClose" onClick={() => setShowOverlay(false)} type="button" />
                                        </div>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Pagination className="paging">{items}</Pagination>
            {statusToast && (
                <Toast onClose={() => setStatusToast(null)} autohide className="toast">
                    <Toast.Header className="toastHeader">
                        <strong className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            {statusToast.status === 'success' ? 'Success' : 'Error'}
                        </strong>
                    </Toast.Header>
                    <Toast.Body>{statusToast.message}</Toast.Body>
                </Toast>
            )}
        </div>
    );
};

export default Locations;