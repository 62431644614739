import Header from "../global/Header";
import "../admins/admin.css";
import { useState, useCallback, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { fetchAdmin, createAdmin, DeleteAdmin } from "../global/axios";
import { Spinner, Pagination, Toast } from "react-bootstrap";

const Admins = () => {
    const [showOverlay2, setShowOverlay2] = useState(false);
    const [adminsData, setAdminData] = useState(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [IDAdmin, setIDAdmin] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [showOverlayDelete, setShowOverlayDelete] = useState(false);
    const [statusToast, setStatusToast] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            const data = await fetchAdmin();
            setAdminData(data.data);
        } catch (error) {
            console.error("Error fetching admin data:", error);
            setStatusToast({ status: 'error', message: 'Error fetching admin data' });

        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    let totalPages, currentItems;
    if (adminsData) {
        totalPages = Math.ceil(adminsData.length / itemsPerPage);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentItems = adminsData.slice(indexOfFirstItem, indexOfLastItem);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }

    if (adminsData === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    const handleCreate = async () => {
        if (!name || !phone || !email) {
            setStatusToast({ status: 'error', message: 'Please fill all required fields' });
            return;
        }
    
        try {
            const payload = {
                name: name,
                phone: phone,
                email: email,
            };
    
            const response = await createAdmin(payload);
            console.log('API response:', response);
            fetchData();
            setName('');
            setPhone('');
            setEmail('');
            setStatusToast({ status: 'success', message: 'Admin created successfully!' });
        } catch (error) {
            console.error('Error creating Admin:', error);
            if (error.response && error.response.status === 500) {
                setStatusToast({ status: 'error', message: 'Admin already exist with the same name' });
            }
        }
    };
    

    const DelAdmin = async (admintobedel) => {
        try {
            const AdminID = localStorage.getItem('AdminID');
            const response = await DeleteAdmin(admintobedel, AdminID);
            fetchData();
            setStatusToast({ status: 'success', message: 'Admin deleted successfully!' });

            return response;

        } catch (error) {
            setStatusToast({ status: 'error', message: 'Error occurred while deleting admin' });
        }
    }

    return (

        <div>
            <Header title={"Admins"} />
            <div>
                <div className="createButtonHeader">
                    <div className="createButton" type="button" onClick={() => setShowOverlay2(true)}>Create</div>
                    {showOverlay2 && (
                        <>
                            <div className="overlayBackground" onClick={() => setShowOverlay2(false)}></div>
                            <div className="overlayAdmin">
                                <div className="createOverlay3">
                                    <div className="createOverlayTitle">Create A New Admin:</div>
                                    <div className="entry">
                                        <div className="entryTitle">Name:</div>
                                        <input className="entryInput" onChange={(e) => setName(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Email:</div>
                                        <input className="entryInput" type="email" onChange={(e) => setEmail(e.target.value)}></input>
                                    </div>
                                    <div className="entry">
                                        <div className="entryTitle">Phone Number:</div>
                                        <input className="entryInput" onChange={(e) => setPhone(e.target.value)}></input>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                        <div className="submitCreation" type="button" onClick={() => { handleCreate(); setShowOverlay2(false); }}> Submit </div>
                                    </div>
                                </div>
                                <CloseIcon className="overlayClose" onClick={() => setShowOverlay2(false)} type="button" />
                            </div>
                        </>
                    )}


                </div>
                <table className="adminsTable">
                    <thead className="interestTableHeading">
                        <tr>
                            <th className="interestTableHead2">Id</th>
                            <th className="interestTableHead">Name</th>
                            <th className="interestTableHead">Email</th>
                            <th className="interestTableHead">Phone Number</th>
                            <th className="interestTableHead"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((admin) => (
                            <tr key={admin.id} className="interestTableRow">
                                <td className="interestTableData2">{admin.id}</td>
                                <td className="interestTableData">{admin.name}</td>
                                <td className="interestTableData">{admin.email}</td>
                                <td className="interestTableData">{admin.phone}</td>
                                <td className="interestTableData3"><div className="deleteAdminButton" type="button" onClick={() => { setIDAdmin(admin.id); setShowOverlayDelete(true) }}>Delete</div></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {showOverlayDelete && (
                <>
                    <div className="overlayBackground" onClick={() => setShowOverlayDelete(false)}></div>
                    <div className="overlayDelete">
                        <div className="createOverlayDelete">
                            <CloseIcon onClick={() => setShowOverlayDelete(false)} type="button" className="closeButton2" />
                            <div className="createOverlayTitleDelete"> <div> Delete Admin </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="deleteMessageWarning">Are you sure you want to delete? This process can't be undone.</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                <div className="submitCreation" type="button" style={{ background: "#D43A2F", borderColor: "#A71F1F" }} onClick={() => { DelAdmin(IDAdmin); setShowOverlayDelete(false); }}> Delete </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <div>
                <Pagination className="paging">{items}</Pagination>
            </div>
            {statusToast && (
                <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                    <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                        <div >
                            {statusToast.status === 'success' ? 'Success' : 'Error'}
                        </div>
                    </Toast.Header>
                    <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                </Toast>
            )}
        </div>
    );
};

export default Admins;