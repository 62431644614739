import "../AllUsers/users.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SearchIcon from "@mui/icons-material/Search";
import GeneralInfo from "./middleSection/generalInformation";
import UserProducts from "./middleSection/userProducts";
import SupportTable from "./middleSection/table";
import { ReactComponent as CloseIcon } from "../../products/CloseIcon.svg";
import { useState, useEffect } from "react";
import { fetchUserdata, handleStauts, deleteUser, fetchCategories } from "../../global/axios";
import { Spinner, Pagination, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../UserDashboard/userDashboard.css';




const Users = () => {

    const [selectedUser, setSelectedUser] = useState(null);
    const [activeUser, setActiveUser] = useState(null);
    const [usermessage, setMessageUser] = useState(null);
    const [users, setUserdata] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(6);
    const [loading, setLoading] = useState(true);
    const [showOverlayDelete, setShowOverlayDelete] = useState(false);
    const [statusToast, setStatusToast] = useState(null);
    const [userState, setuserState] = useState(null);
    const [statusColor, setStatusColor] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [categoriesData, setCategoriesData] = useState([]);





    const getStatusColorClass = (status) => {
        switch (status) {
            case 'Active':
                return 'accountStatusActive';
            case 'Suspended':
                return 'accountStatusSuspended';
            case 'Pending':
                return 'accountStatusPending';
            case 'In Active':
                return 'accountStatusInactive';
            default:
                return '';
        }
    };
    const Handlesuspended = async (selectedUser) => {
        try {
            setLoading(true);
            let newStatus = "";

            if (selectedUser.status.toUpperCase() === 'ACTIVE') {
                newStatus = "SUSPENDED";
                setuserState("Suspended");
                setStatusColor(getStatusColorClass("Suspended"));
                selectedUser.status = 'SUSPENDED';
            }
            else {
                newStatus = "ACTIVE";
                setuserState("Active");
                setStatusColor(getStatusColorClass("Active"));
                selectedUser.status = 'Active';
            }
            await handleStauts(selectedUser.id, newStatus);
            fetchData();
            setLoading(false);
            setStatusToast({ status: 'success', message: `User status changed to ${newStatus.toLowerCase()}` });
        } catch (error) {
            console.error('Error fetching Handle Stauts:', error);
            setStatusToast({ status: 'error', message: 'Error changing user status' });
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await fetchCategories()
            setCategoriesData(data.data);
            const user = await fetchUserdata({ pageNumber: currentPage, size: itemsPerPage });
            setUserdata(user.data);
            setTotalPages(user.totalPages);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // const fetchDataAndSetLoading = useCallback(async () => {
    //     try {
    //         await fetchData();
    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(false);
    //         console.error('Error fetching data:', error);
    //     }
    // }, [fetchData]);

    useEffect(() => {
        fetchData();
    }, [itemsPerPage, currentPage]);

    useEffect(() => {
        // Logic to set the selected user based on userId and users
        if (activeUser && users) {
            const foundUser = users.find(user => user.id === activeUser);
            console.log('foundUser:', foundUser);
            if (foundUser) {
                setSelectedUser(foundUser);
                setActiveUser(foundUser.id);
                setMessageUser(foundUser.id);
            }
        }
    }, [activeUser, users]);

    const pageSizes = [10, 20, 30, 40, 50, 100, 250, 500];
    const handlePageSize = (size) => {
        const pageSize = parseInt(size, 10); // Convert string to number
        setItemsPerPage(pageSize);
        setCurrentPage(0); // Reset to the first page when changing the page size
    };

    const sortedUsers = users ? users.sort((a, b) => a.name.localeCompare(b.name)) : [];
    // let totalPages, currentItems;
    // if (sortedUsers) {
    //     totalPages = Math.ceil(sortedUsers.length / itemsPerPage);
    //     const indexOfLastItem = currentPage * itemsPerPage;
    //     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //     currentItems = sortedUsers.slice(indexOfFirstItem, indexOfLastItem);
    // }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function PaginatedItems({ totalPages, currentPage, handlePageChange }) {
        const items = [];

        // Add "Previous" button
        items.push(
            <Pagination.Prev
                key="prev"
                disabled={currentPage === 0}
                onClick={() => handlePageChange(currentPage - 1)}
            />
        );

        const maxVisiblePages = 5;

        // Determine the range of pages to display
        let startPage = Math.max(0, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages);

        // Adjust start and end when at the beginning or end of the page range
        if (endPage - startPage < maxVisiblePages) {
            startPage = Math.max(0, endPage - maxVisiblePages);
        }

        // Create pagination items
        for (let number = startPage; number < endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number + 1}
                </Pagination.Item>
            );
        }

        // Add "Next" button
        items.push(
            <Pagination.Next
                key="next"
                disabled={currentPage === totalPages - 1}
                onClick={() => handlePageChange(currentPage + 1)}
            />
        );

        return <Pagination>{items}</Pagination>;
    }





    // Group users by first character
    const groupedUsers = users ? users.reduce((acc, user) => {
        const firstChar = user.name.charAt(0).toUpperCase();
        acc[firstChar] = acc[firstChar] || [];
        acc[firstChar].push(user);
        return acc;
    }, {}) : {};

    const handleDivClickBlock = (user) => {
        setActiveUser(user.id);
        setMessageUser(user.id);
        setSelectedUser(user);
        setuserState(user.status);
        setStatusColor(getStatusColorClass(user.status));

    };
    const HandledeleteUser = async () => {
        try {
            setLoading(true);
            await deleteUser(selectedUser.id);
            await fetchData()
                .then(() => {
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                });

        } catch (error) {

        };

    }
    if (users === null || loading) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    return (
        <div style={{ width: "87%", height: "90vh" }}>
            {/* Title */}
            <div style={{ display: "flex", margin: "20px", alignItems: "center", width: "fit-content", marginBottom: "30px" }}>
                <Link to="/userDashboard" style={{ textDecoration: "none" }}>
                    <div type="button" className="buttonReturn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path opacity="0.7" d="M12.1728 15.5999L3.27344 7.9999L12.1728 0.399902L12.7266 1.04744L4.58555 7.9999L12.7266 14.9524" fill="white" />
                        </svg>
                    </div>
                </Link>
                <div style={{ color: "white", fontSize: 26, fontFamily: "Montserrat", fontWeight: "700", wordWrap: "break-word", letterSpacing: "1.5px", display: "inline", marginLeft: "10px" }}>
                    All Users
                </div>
            </div>

            <hr style={{ color: "GrayText", width: "100%", marginBottom: "0px" }} />

            <div style={{ height: "100%", display: "flex", borderRightColor: "GrayText", borderTop: "1px solid" }}>
                {/* First Section */}
                <div style={{ height: "100%", borderRightColor: "GrayText", borderRight: "1px solid", width: "20%" }}>
                    <div style={{ height: "45px", borderBottom: "2px solid", display: "flex", alignItems: "center" }} >
                        <div style={{ marginLeft: "0px", width: "20px", alignItems: "center" }} >
                            <SearchIcon style={{ color: "#9A9A9A", marginLeft: "5px" }} />
                        </div>
                        <input
                            type="text"
                            placeholder="Search Users"
                            value={searchTerm}
                            style={{ height: "20px", backgroundColor: "#121212", marginLeft: "10px", border: "none", width: "80%" }}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div>
                        {Object.entries(groupedUsers).map(([char, users]) => (
                            <div key={char}>
                                <div className="line" >
                                    <span>{char}</span>
                                </div>
                                <div>
                                    {users
                                        .filter((user) => (user.name.toLowerCase().includes(searchTerm.toLowerCase())) || (user.username.toLowerCase().includes(searchTerm.toLowerCase())))
                                        .map((user) => (
                                            <div key={user.id} className={activeUser === user.id ? 'activo userBlock ' : 'userBlock '} onClick={() => handleDivClickBlock(user)} type="button">
                                                <div className="circleProfileSmall">{user.name.charAt(0)}</div>
                                                <div>
                                                    <div className="userNameAllUsers">{user.name}</div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div className="blockSubtitle">@</div>
                                                        <div className="blockNumber">{user.username}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="paginationContainer">
                        <PaginatedItems
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handlePageChange={(page) => handlePageChange(page)}
                        />
                        <div style={{ color: "#9A9A9A", marginRight: "10px" }}>Page limit:</div>
                        <select
                            className="pageSizeDropdown"
                            value={itemsPerPage}
                            onChange={(e) => handlePageSize(e.target.value)}
                        >
                            {pageSizes.map((size) => (
                                <option value={size} key={size}>
                                    {size}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* middle Section */}

                <div style={{ width: "70%", backgroundColor: "black", height: "90vh" }}>
                    <Tabs
                        defaultActiveKey="generalInformation"
                        className="mb-3 mainTabs">
                        <Tab eventKey="generalInformation" title="General Information">
                            <GeneralInfo
                                user={selectedUser}
                                refreshUserData={fetchData}
                                categoriesData={categoriesData}
                                setStatusToast={setStatusToast}
                            />
                        </Tab>
                        <Tab eventKey="products" title="Products">
                            <UserProducts user={usermessage} />
                        </Tab>

                        <Tab eventKey="support" title="Support">
                            <SupportTable user={usermessage} />
                        </Tab>
                    </Tabs>
                </div>

                <div>
                    {selectedUser && (
                        <div key={selectedUser.id} style={{ width: "250px", height: "90%", borderRightColor: "GrayText", borderLeft: "1px solid" }}>
                            <div className="circleProfileLarge">{selectedUser.name.charAt(0)}</div>
                            <div className="customerName">{selectedUser.name}</div>
                            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <div className="customerId">@</div>
                                <div className="customerNumber" style={{ display: "inline" }}>{selectedUser.id}</div>
                            </div>
                            <div className={statusColor} style={{ margin: "15px" }}>{userState}</div>
                            <div style={{ display: "flex" }}>
                                <div style={{ color: '#A0A0A0', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginLeft: "15px", width: "fit-content" }}>Created:</div>
                                <div style={{ color: '#A0A0A0', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', marginLeft: "5px", width: "fit-content" }}>{selectedUser.joinedAt}
                                </div>
                            </div>
                            <hr
                                style={{ color: "#252525", width: "100%", marginBottom: "0px", border: "2px solid" }}
                            />
                            <div type="button" onClick={() => Handlesuspended(selectedUser)} style={{ width: '100%', height: 'fit-content', color: 'white', fontSize: 12, fontFamily: 'Montserrat', fontWeight: '400', wordWrap: 'break-word', margin: "15px 0 15px 15px" }}>
                                {selectedUser.status.toUpperCase() === 'ACTIVE' ? 'Suspend User' : 'Activate User'}</div>
                            <div type="button" className="deleteUsertab" onClick={() => { setShowOverlayDelete(true) }}>Delete User</div>
                        </div>
                    )}
                </div>
            </div>
            {showOverlayDelete && (
                <>
                    <div className="overlayBackground" onClick={() => setShowOverlayDelete(false)}></div>
                    <div className="overlayDelete">
                        <div className="createOverlayDelete">
                            <CloseIcon onClick={() => setShowOverlayDelete(false)} type="button" className="closeButton2" />
                            <div className="createOverlayTitleDelete"> <div> Delete User </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <div className="deleteMessageWarning">Are you sure you want to delete? This process can't be undone.</div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                <div className="submitCreation" type="button" onClick={() => { HandledeleteUser(); setShowOverlayDelete(false) }} style={{ background: "#D43A2F", borderColor: "#A71F1F" }}> Delete </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {statusToast && (
                <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                    <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                        <div >
                            {statusToast.status === 'success' ? 'Success' : 'Error'}
                        </div>
                    </Toast.Header>
                    <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                </Toast>
            )}
        </div>
    );
};

export default Users;
