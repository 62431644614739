import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import ProductDetails from "./pages/products/details";
import Activity from "./pages/activity/activity.jsx";
import Dashboard from "./pages/dashboard/dashboardIndex";
import UserDashboard from "./pages/users/UserDashboard/userDashboard.jsx";
import Users from "./pages/users/AllUsers/users.jsx";
import Behaviour from "./pages/statistics/userbehaviour/userBehaviour.jsx";
import Login from "./pages/Login/login.jsx";
import Products from "./pages/products/products";
import Reports from "./pages/reports/reports.jsx";
import Interest from "./pages/interests/interest";
import UserStatistics from "./pages/statistics/userStatistics/userStatistics.jsx";
import ProductStatistics from "./pages/statistics/productStatistics/productStatistics.jsx";
import StatisticsDashboard from "./pages/statistics/statDashboard/statisticsDashboard.jsx";
import Categories from "./pages/categories/categories.jsx";
import Admins from "./pages/admins/admin.jsx";
import Locations from "./pages/locations/locations.jsx";
import React from "react";
import {BrowserRouter,Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { initializeAuth } from "./pages/global/axiosconfg";
import axios from 'axios';
//import { Logoutuser } from "./pages/global/axios.jsx";
import { setAuthToken } from "./pages/global/axiosconfg";


function App() {

axios.defaults.baseURL = window.location.origin + '/api/v1/';


useEffect(() => {
  async function fetch(){
    await initializeAuth();
  }
  fetch();

  const interceptor = axios.interceptors.response.use(
    response => response, 
    async (error) => { // Make this function async
      if (error.response && error.response.status === 401) {
//        await Logoutuser(); // Now it's safe to use await here
        localStorage.removeItem("AdminID");
        localStorage.removeItem("AdminName");
        localStorage.removeItem("authToken");

        setAuthToken(null);
        window.location.href = '/';
      }
      return Promise.reject(error);
    }
  );

  return () => {
      axios.interceptors.response.eject(interceptor);
  };
}, []);
  return (
    <div className="app">
        <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path="/dashboard" element={ <> <Sidebar /> <Topbar /> <Dashboard /> </>}/>
          <Route path='/userDashboard' element={<> <Sidebar /> <Topbar /> <UserDashboard /> </>} />
          <Route path="/users" element={<> <Sidebar /> <Topbar /> <Users /> </>} />
          <Route path="/users/:userId" element={<> <Sidebar /> <Topbar /> <Users /> </>} />
          <Route path="/products" element={<> <Sidebar /> <Topbar /> <Products /> </>} />
          <Route path="/productDetails/:productId" element={<> <Sidebar /> <Topbar /> <ProductDetails /> </>} />
          <Route path="/activity" element={<> <Sidebar /> <Topbar /> <Activity /> </>} />
          <Route path="/behaviour"  element={<> <Sidebar /> <Topbar /> <Behaviour /> </>} />
          <Route path="/userStatistics"  element={<> <Sidebar /> <Topbar /> <UserStatistics /> </>} />
          <Route path="/productStatistics"  element={<> <Sidebar /> <Topbar /> <ProductStatistics /> </>} />
          <Route path="/statisticsDashboard"  element={<> <Sidebar /> <Topbar /> <StatisticsDashboard /> </>} />
          <Route path="/reports" element={<> <Sidebar /> <Topbar /> <Reports /> </>} />
          <Route path="/interest" element={<> <Sidebar /> <Topbar /> <Interest /> </>} />
          <Route path="/location" element={<> <Sidebar /> <Topbar /> <Locations /> </>} />
          <Route path="/admins" element={<> <Sidebar /> <Topbar /> <Admins /> </>} />
          <Route path="/categories" element={<> <Sidebar /> <Topbar /> <Categories /> </>} />
        </Routes>
      </BrowserRouter>
    </div>


  );
}



export default App;
