import Header from "../global/Header";
import "../reports/reports.css";
import { useState, useEffect, useCallback } from "react";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { Dropdown } from "react-bootstrap";
import { GenrateNewReport, fetchReports, GenrateReport } from "../global/axios";
import { Spinner, Pagination, Toast } from "react-bootstrap";
import * as XLSX from 'xlsx';

const Reports = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [reportsData, setReportsData] = useState(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedActivity, setSelectedActivity] = useState('Filter Activities');
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [statusToast, setStatusToast] = useState(null);
    const fetchData = useCallback(async () => {
        try {
            const data = await fetchReports();
            setReportsData(data.data);
            setTotalPages(data.totalPages);
            setLoading(false);

        } catch (error) {
            console.error("Error fetching reports data:", error);
            setLoading(false);
            setStatusToast({ status: 'error', message: 'Error fetching report data' });
        }
    }, []);
    const HandleFromDateChange = (event) => {
        setFromDate(event.target.value);

    };

    const HandleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const HandleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const HandleActivityChange = (action) => {
        setSelectedActivity(action);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const CreateNewReport = async () => {
        try {
            setLoading(true);
            const payload = {
                'startDate': fromDate,
                'endDate': toDate,
                'isSavableToDatabase': isChecked,
                'type': selectedActivity,
            }
            const result = await GenrateNewReport(payload);
            console.log(result);
            fetchData();
            setFromDate('');
            setToDate('');
            setIsChecked(false);
            setSelectedActivity('Filter Activities');
            const parsedData = JSON.parse(result.content);
            convertDataToExcel(parsedData, 'GenratedReport');
            setLoading(false);
            setStatusToast({ status: 'success', message: 'New report generated successfully!' });

        } catch (error) {
            console.error('Error genrating new report:', error);
            fetchData();
            setFromDate('');
            setToDate('');
            setIsChecked(false);
            setSelectedActivity('Filter Activities');
            setLoading(false);
            if (error.response && error.response.status === 500) {
                setStatusToast({ status: 'error', message: 'No data found within the given time range' });
            }
        }
    };

    function convertDataToExcel(data, filename) {
        if (!data || data.length === 0) {
            console.error('Data is empty.');
            return;
        }

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert the workbook to a data URL
        const excelDataUrl = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' });

        // Create a data URL with a "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" MIME type
        const dataUri = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelDataUrl}`;

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = dataUri;
        a.download = `${filename}.xlsx`;

        // Trigger the download
        a.click();
    }
    const DGenrateReport = async (ReportId) => {
        try {
            const result = await GenrateReport(ReportId);
            const parsedData = JSON.parse(result.content);
            convertDataToExcel(parsedData, 'GenratedReport');
            setStatusToast({ status: 'success', message: 'Report Generated successfully!' });
        } catch (error) {
            throw error;
        }

    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }

    if (reportsData === null || loading) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    return (
        <div style={{ width: "88%" }}>
            <Header title={"Reports"} />
            <div style={{ width: "78%", justifyContent: "right", display: "flex" }}>
                <div className="createButton" type="button" onClick={() => setShowOverlay(true)}>Create New Report</div>
            </div>
            <div style={{ margin: "0px 0px 0px 20px" }}>
                <div className="reportMainTitle"> Recently Generated Reports </div>
                <div className="mainReportBlocks">
                    {reportsData.slice(0, 5).map((reports) => (
                        <div className="reportBlock" key={reports.id}>
                            <div className="reportTitle">{reports.type}</div>
                            <div className="reportSubtitle1">Start date: {reports.startDate}</div>
                            <div className="reportSubtitle1">End date: {reports.endDate}</div>

                            <div className="generateReportButton" type="button" onClick={() => DGenrateReport(reports.id)}>Generate Report</div>
                        </div>
                    ))}
                </div>
                <div style={{display:"flex", alignContent:"center", height:"38px", marginBottom:"10px"}}>
                    <div className="reportMainTitle2">All Reports </div>
                    <Pagination className="paging2" style={{width:"fit-content"}}>{items}
                    </Pagination>
                </div>
                <div className="mainReportBlocks">
                    {reportsData.map((reports) => (
                        <div className="reportBlock" key={reports.id}>
                            <div className="reportTitle">{reports.type}</div>
                            <div className="reportSubtitle1">Start date: {reports.startDate}</div>
                            <div className="reportSubtitle1">End date: {reports.endDate}</div>
                            <div className="generateReportButton" type="button" onClick={() => DGenrateReport(reports.id)}>Generate Report</div>
                        </div>
                    ))}
                    {showOverlay && (
                        <>
                            <div className="overlayBackground" onClick={() => setShowOverlay(false)}></div>
                            <div className="overlay" >
                                <CloseIcon className="overlayClose" onClick={() => setShowOverlay(false)} type="button" />
                                <div style={{ margin: "20px", marginRight: "20px" }}>
                                    <div className="reportOverlayTitle">Generate New Report</div>
                                    {/* 
                                        <div className="reportOverlaySubtitle"> Vestibulum vel viverra erat, vitae eleifend augue. Mauris felis metus, consectetur sit amet libero eget, feugiat sollicitudin erat. Phasellus a metus tortor. Vestibulum in neque ex. Nulla ligula erat, volutpat et urna sit amet, cursus pretium elit.</div>
                                        */}
                                    <hr style={{ background: "#252525" }}></hr>
                                    {/* 
                                        <div className="reportOverlayTitle2">Generate New Report</div>
                                        */}
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle className="button1">
                                                {selectedActivity}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="reportFilterActions">
                                                <Dropdown.Item className="actionSidebar" onClick={() => HandleActivityChange('USERS')} >Users</Dropdown.Item>
                                                <Dropdown.Item className="actionSidebar" onClick={() => HandleActivityChange('ITEMS')}>Items</Dropdown.Item>
                                                <Dropdown.Item className="actionSidebar" onClick={() => HandleActivityChange('SWAPS')} >Swaps</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="dateRange" style={{ marginBottom: "6px", marginTop: "5px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20" fill="none">
                                                <path d="M18.3337 1.875H15.625V0.625C15.625 0.279375 15.3456 0 15 0C14.6544 0 14.375 0.279375 14.375 0.625V1.875H10.625V0.625C10.625 0.279375 10.345 0 10 0C9.655 0 9.375 0.279375 9.375 0.625V1.875H5.625V0.625C5.625 0.279375 5.345 0 5 0C4.655 0 4.375 0.279375 4.375 0.625V1.875H1.66688C0.74625 1.875 0 2.62063 0 3.54125V18.3331C0 19.2537 0.74625 20 1.66688 20H18.3337C19.2544 20 20 19.2537 20 18.3331V3.54125C20 2.62063 19.2544 1.875 18.3337 1.875ZM18.75 18.3331C18.75 18.5631 18.5631 18.75 18.3337 18.75H1.66688C1.43687 18.75 1.25 18.5631 1.25 18.3331V3.54125C1.25 3.31187 1.43687 3.125 1.66688 3.125H4.375V4.375C4.375 4.72062 4.655 5 5 5C5.345 5 5.625 4.72062 5.625 4.375V3.125H9.375V4.375C9.375 4.72062 9.655 5 10 5C10.345 5 10.625 4.72062 10.625 4.375V3.125H14.375V4.375C14.375 4.72062 14.6544 5 15 5C15.3456 5 15.625 4.72062 15.625 4.375V3.125H18.3337C18.5631 3.125 18.75 3.31187 18.75 3.54125V18.3331Z" fill="#9A9A9A" />
                                                <path d="M6.875 7.5H4.375V9.375H6.875V7.5Z" fill="#9A9A9A" />
                                                <path d="M6.875 10.625H4.375V12.5H6.875V10.625Z" fill="#9A9A9A" />
                                                <path d="M6.875 13.75H4.375V15.625H6.875V13.75Z" fill="#9A9A9A" />
                                                <path d="M11.25 13.75H8.75V15.625H11.25V13.75Z" fill="#9A9A9A" />
                                                <path d="M11.25 10.625H8.75V12.5H11.25V10.625Z" fill="#9A9A9A" />
                                                <path d="M11.25 7.5H8.75V9.375H11.25V7.5Z" fill="#9A9A9A" />
                                                <path d="M15.625 13.75H13.125V15.625H15.625V13.75Z" fill="#9A9A9A" />
                                                <path d="M15.625 10.625H13.125V12.5H15.625V10.625Z" fill="#9A9A9A" />
                                                <path d="M15.625 7.5H13.125V9.375H15.625V7.5Z" fill="#9A9A9A" />
                                            </svg>
                                            <div style={{ marginLeft: "5px" }}>Date Range:</div>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div className="from">From</div>
                                            <input className="date" type="date" min="2020-01-01" style={{ width: "115px" }} value={fromDate} onChange={HandleFromDateChange} />
                                            <div className="from">To</div>
                                            <input className="date" type="date" min="2020-01-01" style={{ width: "115px" }} value={toDate} onChange={HandleToDateChange} />
                                        </div>
                                        <div style={{ display: "flex", marginTop: "20px", marginLeft: "5px" }}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="flexCheckDefault"
                                                onChange={HandleCheckboxChange}
                                            />
                                            <div style={{ marginLeft: "10px" }}>Save Report</div>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div className="generateReportButton" type="button" onClick={() => { CreateNewReport(); setShowOverlay(false) }}>Create Report</div>
                                        </div>
                                    </div>


                                    {/* 
                                        <hr style={{ background: "#252525" }}></hr>
                                        <div className="reportOverlayTitle3">Previously Generated Reports</div>
                                        <table className="reportTable">
                                            <thead className="reportTableHeading">
                                                <tr>
                                                    <th className="reportTableHead2">Generated By</th>
                                                    <th className="reportTableHead">Date</th>
                                                    <th className="reportTableHead">Type</th>
                                                    <th className="reportTableHead3"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <td className="interestTableData2">Islam Shams</td>
                                                <td className="interestTableData">Sept. 28, 2023</td>
                                                <td className="interestTableData">Users</td>
                                                <td className="interestTableData">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none" type="button">
                                                        <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" fill="#0047FF" stroke="#2962F4" />
                                                        <path d="M15.3975 18.2351C15.2955 18.3467 15.1513 18.4103 15.0001 18.4103C14.8489 18.4103 14.7047 18.3467 14.6027 18.2351L11.7309 15.0941C11.5302 14.8746 11.5455 14.5341 11.765 14.3334C11.9845 14.1327 12.325 14.1479 12.5257 14.3674L14.4616 16.4849V8.53846C14.4616 8.24108 14.7027 8 15.0001 8C15.2975 8 15.5386 8.24108 15.5386 8.53846V16.4849L17.4745 14.3674C17.6752 14.1479 18.0158 14.1327 18.2353 14.3334C18.4547 14.5341 18.4699 14.8746 18.2693 15.0941L15.3975 18.2351Z" fill="white" />
                                                        <path d="M9.07692 17.1537C9.07692 16.8563 8.83585 16.6152 8.53846 16.6152C8.24108 16.6152 8 16.8563 8 17.1537V17.1931C7.99999 18.175 7.99997 18.9664 8.08366 19.5888C8.17054 20.2351 8.35641 20.7791 8.78856 21.2113C9.22071 21.6435 9.76482 21.8293 10.4111 21.9162C11.0335 21.9998 11.8249 21.9998 12.8068 21.9998H17.1933C18.1751 21.9998 18.9665 21.9998 19.589 21.9162C20.2352 21.8293 20.7793 21.6435 21.2115 21.2113C21.6436 20.7791 21.8295 20.2351 21.9164 19.5888C22 18.9664 22 18.175 22 17.1931V17.1537C22 16.8563 21.7589 16.6152 21.4615 16.6152C21.1642 16.6152 20.9231 16.8563 20.9231 17.1537C20.9231 18.1842 20.9219 18.903 20.8491 19.4453C20.7782 19.9721 20.6487 20.2511 20.4499 20.4498C20.2512 20.6485 19.9723 20.778 19.4455 20.8489C18.9031 20.9218 18.1844 20.9229 17.1538 20.9229H12.8462C11.8156 20.9229 11.0969 20.9218 10.5545 20.8489C10.0277 20.778 9.74876 20.6485 9.55006 20.4498C9.35136 20.2511 9.22181 19.9721 9.15098 19.4453C9.07806 18.903 9.07692 18.1842 9.07692 17.1537Z" fill="white" />
                                                    </svg>
                                                </td>
                                                </tr>
                                                <tr>
                                                <td className="interestTableData2">Islam Shams</td>
                                                <td className="interestTableData">Sept. 28, 2023</td>
                                                <td className="interestTableData">Users</td>
                                                <td className="interestTableData">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 30 30" fill="none" type="button">
                                                        <rect x="0.5" y="0.5" width="29" height="29" rx="3.5" fill="#0047FF" stroke="#2962F4" />
                                                        <path d="M15.3975 18.2351C15.2955 18.3467 15.1513 18.4103 15.0001 18.4103C14.8489 18.4103 14.7047 18.3467 14.6027 18.2351L11.7309 15.0941C11.5302 14.8746 11.5455 14.5341 11.765 14.3334C11.9845 14.1327 12.325 14.1479 12.5257 14.3674L14.4616 16.4849V8.53846C14.4616 8.24108 14.7027 8 15.0001 8C15.2975 8 15.5386 8.24108 15.5386 8.53846V16.4849L17.4745 14.3674C17.6752 14.1479 18.0158 14.1327 18.2353 14.3334C18.4547 14.5341 18.4699 14.8746 18.2693 15.0941L15.3975 18.2351Z" fill="white" />
                                                        <path d="M9.07692 17.1537C9.07692 16.8563 8.83585 16.6152 8.53846 16.6152C8.24108 16.6152 8 16.8563 8 17.1537V17.1931C7.99999 18.175 7.99997 18.9664 8.08366 19.5888C8.17054 20.2351 8.35641 20.7791 8.78856 21.2113C9.22071 21.6435 9.76482 21.8293 10.4111 21.9162C11.0335 21.9998 11.8249 21.9998 12.8068 21.9998H17.1933C18.1751 21.9998 18.9665 21.9998 19.589 21.9162C20.2352 21.8293 20.7793 21.6435 21.2115 21.2113C21.6436 20.7791 21.8295 20.2351 21.9164 19.5888C22 18.9664 22 18.175 22 17.1931V17.1537C22 16.8563 21.7589 16.6152 21.4615 16.6152C21.1642 16.6152 20.9231 16.8563 20.9231 17.1537C20.9231 18.1842 20.9219 18.903 20.8491 19.4453C20.7782 19.9721 20.6487 20.2511 20.4499 20.4498C20.2512 20.6485 19.9723 20.778 19.4455 20.8489C18.9031 20.9218 18.1844 20.9229 17.1538 20.9229H12.8462C11.8156 20.9229 11.0969 20.9218 10.5545 20.8489C10.0277 20.778 9.74876 20.6485 9.55006 20.4498C9.35136 20.2511 9.22181 19.9721 9.15098 19.4453C9.07806 18.903 9.07692 18.1842 9.07692 17.1537Z" fill="white" />
                                                    </svg>
                                                </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        */}
                                </div>

                            </div>
                        </>
                    )}

                </div>
                {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}

            </div>
        </div>

    );
};

export default Reports;