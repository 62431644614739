import Header from "../global/Header";
import "../categories/categories.css";
import { fetchCategories, HandleEditCategory, HandleNewImageSubmit, HandleSubNewImageSubmit, DeleteSubCategory } from "../global/axios";
import { ReactComponent as CloseIcon } from "../products/CloseIcon.svg";
import { Spinner, Pagination, Toast } from "react-bootstrap";
import { useEffect, useCallback, useState } from "react";

const Categories = () => {
    const [showOverlay1, setShowOverlay1] = useState(false);
    const [showOverlay2, setShowOverlay2] = useState(false);
    const [showOverlay3, setShowOverlay3] = useState(false);
    const [showOverlay4, setShowOverlay4] = useState(false);
    const [showOverlayDelete, setShowOverlayDelete] = useState(false);
    const [categoriesData, setCategoriesData] = useState(null);
    const [selectedMainCategory, setSelectedMainCategory] = useState(null);
    const [selectedMainCategoryIcon, setSelectedMainCategoryIcon] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const [base64Image, setBase64Image] = useState('');
    const [name, setName] = useState('');
    const [subname, setSubName] = useState('');
    const [subNewName, setSubNewName] = useState('')
    const [Newname, setNewName] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedSubCategoryIcon, setSelectedSubCategoryIcon] = useState('');
    const [file, setFile] = useState(null);
    const [Loading, setLoading] = useState(true);
    const [statusToast, setStatusToast] = useState(null);
    const [currentSubPage, setCurrentSubPage] = useState(1);



    function imageToBase64(file, callback) {
        const reader = new FileReader();

        reader.onload = function () {
            const base64String = reader.result.split(',')[1]; // Extract the Base64 string part
            callback(base64String);
        };

        reader.readAsDataURL(file);
    }

    const selectSubCategorey = (subCategories) => {
        if (subCategories.name === "Other") {
            setSelectedSubCategory(null);
        } else {
            setSelectedSubCategory(subCategories.id);
            setSubName(subCategories.name);
            setSelectedSubCategoryIcon(subCategories.icon);
            console.log(subCategories.icon)
        }
    }
    const handleImageUpload = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        if (selectedFile) {
            imageToBase64(selectedFile, (base64String) => {
                setBase64Image(base64String);
            });
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleNewName = (e) => {
        setNewName(e.target.value);
    }

    const handleSubNameChange = (e) => {
        setSubName(e.target.value);
    }
    const handleSubNewNameChange = (e) => {
        setSubNewName(e.target.value);
    }

    const handleImageSubmit = async () => {
            try {
                setLoading(true);
                const payload = {
                    'name': name,
                }
                if (base64Image) {
                    payload['icon'] = base64Image;
                } else {
                    payload['icon'] = selectedMainCategoryIcon;
                }
                await HandleEditCategory(selectedMainCategory, payload)
                fetchData();
                setBase64Image('');
                setName('');
                setLoading(false);
                setStatusToast({ status: 'success', message: 'Main-Category Updated successfully!' });

            } catch (error) {
                console.error(error);
                setStatusToast({ status: 'error', message: 'Error Updating Main-category' });
            }
    };

    const handleNewImageSubmit = async () => {
        if (Newname && base64Image && file) {
            try {
                setLoading(true);
                const payload = {
                    'name': Newname,
                    'icon': base64Image
                }
                await HandleNewImageSubmit(payload)
                fetchData();
                setBase64Image('');
                setNewName('');
                setLoading(false);
                setStatusToast({ status: 'success', message: 'New Main-category created successfully!' });
            } catch (error) {
                console.error(error);
                setLoading(false);
                setStatusToast({ status: 'error', message: 'Error creating Main-category' });
            }
        } else {
            setStatusToast({ status: 'error', message: 'Please fill all the required fields' });
        }
    };
    
    const handleSubImageSubmit = async () => {
        try {
            setLoading(true);
            const payload = {
                'name': subname,
            };

            if (base64Image) {
                payload['icon'] = base64Image;
            } else {
                payload['icon'] = selectedSubCategoryIcon;
            }
            await HandleEditCategory(selectedSubCategory, payload)
            fetchData();
            setBase64Image('');
            setSubName('');
            setLoading(false);
            setStatusToast({ status: 'success', message: 'Sub-Category Updated successfully!' });

        } catch (error) {
            console.error(error);
            setStatusToast({ status: 'error', message: 'Error Updating Sub-category' });
        }

    };

    const handleSubNewImageSubmit = async () => {
        if (subNewName && base64Image && file) {
            try {
                setLoading(true);
                const payload = {
                    'name': subNewName,
                    'icon': base64Image
                }
                await HandleSubNewImageSubmit(selectedMainCategory, payload)
                setBase64Image('');
                setSubNewName('');
                fetchData()
                    .then(() => {
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                    });
                setStatusToast({ status: 'success', message: 'New Sub-category created successfully!' });
            } catch (error) {
                console.error(error);
                setLoading(false);
                setStatusToast({ status: 'error', message: 'Error Uploading' });
            }
        } else {
            setStatusToast({ status: 'error', message: 'Please fill all the required fields' });
        }
    };
    
    const HandleDelSub = async () => {
        try {
            setLoading(true);
            await DeleteSubCategory(selectedSubCategory);
            fetchData()
                .then(() => {
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                });

            setStatusToast({ status: 'success', message: 'successfully  Deleted' });
        } catch (error) {
            setStatusToast({ status: 'error', message: 'Error  Deleting' });

        }
    }
    const fetchData = useCallback(async () => {
        try {
            
            const data = await fetchCategories();
            setCategoriesData(data.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching categories data:", error);
            setStatusToast({ status: 'error', message: 'Error fetching categories data ' });
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleMainCategoryClick = (mainCategoryInfo) => {
        // Toggle selection: If already selected, deselect; otherwise, select.
        setSelectedMainCategory((prevSelected) =>
            prevSelected === mainCategoryInfo.mainInfo.id ? null : mainCategoryInfo.mainInfo.id
        );
        setName(mainCategoryInfo.mainInfo.name);
        setSelectedMainCategoryIcon(mainCategoryInfo.mainInfo.icon)
    };

    let totalPages, currentItems;
    if (categoriesData) {
        totalPages = Math.ceil(categoriesData.length / itemsPerPage);
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        currentItems = categoriesData.slice(indexOfFirstItem, indexOfLastItem);
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    };

    let subcategoriesToPaginate = [];
    if (selectedMainCategory) {
        const mainCategory = categoriesData.find(cat => cat.mainInfo.id === selectedMainCategory);
        // console.log("categoriesData",categoriesData);
        // console.log("mainCategory.subCategories",mainCategory.subCategories);
        // console.log("selectedMainCategory",selectedMainCategory);
        if (mainCategory) {
            subcategoriesToPaginate = mainCategory.subCategories;
        }
    }

    // Apply pagination logic to subcategories
    let totalPagesSubcategories, currentSubItems;
    if (subcategoriesToPaginate) {
        totalPagesSubcategories = Math.ceil(subcategoriesToPaginate.length / itemsPerPage);
        const indexOfLastSubItem = currentSubPage * itemsPerPage;
        const indexOfFirstSubItem = indexOfLastSubItem - itemsPerPage;
        currentSubItems = subcategoriesToPaginate.slice(indexOfFirstSubItem, indexOfLastSubItem);
    }

    // Function to handle page change for subcategories
    const handleSubPageChange = (pageNumber) => {
        setCurrentSubPage(pageNumber);
    };

    // Pagination items for subcategories
    let subcategoryPaginationItems = [];
    for (let number = 1; number <= totalPagesSubcategories; number++) {
        subcategoryPaginationItems.push(
            <Pagination.Item key={number} active={number === currentSubPage} onClick={() => handleSubPageChange(number)}>
                {number}
            </Pagination.Item>
        );
    };

    if (categoriesData === null || Loading || imageToBase64 === null) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }

    return (
        <div>
            <Header title={"Categories"} />
            <div className="category">
                <div className="mainCategories">
                    <div className="categoryTitleContainer">
                        <div className="categoryTitle">Main Categories</div>
                        <div className="categoryNewButton" type="button" onClick={() => setShowOverlay1(true)}> + </div>
                        <div className="categoryEditButton" type="button" onClick={() => { setShowOverlay2(true) }}>Edit</div>
                        {showOverlay1 && (
                            <>
                                <div className="overlayBackground" onClick={() => setShowOverlay2(false)}></div>
                                <div className="overlay">
                                    <div className="createOverlay3">
                                        <div className="createOverlayTitle" style={{ marginBottom: "35px" }}>Create Main Category:</div>
                                        <div className="entry">
                                            <div className="entryTitle">Name:</div>
                                            <input className="entryInput" value={Newname}
                                                onChange={handleNewName}></input>
                                        </div>
                                        <div className="entry" style={{ marginBottom: "35px" }}>
                                            <div className="entryTitle">Upload:</div>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/png"
                                                onChange={handleImageUpload}
                                                maxsize={1024 * 1024 * 10} // 10MB (in bytes)
                                            />                                    </div>
                                        <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                            <div className="submitCreation" type="button" onClick={() => { handleNewImageSubmit(); setShowOverlay1(false); }}> Submit </div>
                                        </div>
                                    </div>
                                    <CloseIcon className="overlayClose" onClick={() => setShowOverlay1(false)} type="button" />
                                </div>
                            </>
                        )}
                        {showOverlay2 && (
                            <>
                                <div className="overlayBackground" onClick={() => setShowOverlay2(false)}></div>
                                <div className="overlay">
                                    <div className="createOverlay3">
                                        <div className="createOverlayTitle" style={{ marginBottom: "35px" }}>Edit Main Category:</div>
                                        <div className="entry">
                                            <div className="entryTitle">Name:</div>
                                            <input className="entryInput" value={name}
                                                onChange={handleNameChange}></input>
                                        </div>
                                        <div className="entry" style={{ marginBottom: "35px" }}>
                                            <div className="entryTitle">Upload:</div>
                                            <input
                                                type="file"
                                                accept="image/jpeg, image/png"
                                                onChange={handleImageUpload}
                                                maxsize={1024 * 1024 * 10} // 10MB (in bytes)
                                            />

                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                            <div className="submitCreation" type="button" onClick={() => { handleImageSubmit(); setShowOverlay2(false); }}> Submit </div>
                                        </div>
                                    </div>
                                    <CloseIcon className="overlayClose" onClick={() => setShowOverlay2(false)} type="button" />
                                </div>
                            </>
                        )}
                    </div>
                    <hr style={{ background: "#252525", height: "2px", margin: "0px" }} />
                    {currentItems.map((category) => (
                        <div className={`${selectedMainCategory === category.mainInfo.id ? 'selectedCategory' : 'mainCategoryContainer'}`}
                            key={category.mainInfo.id} type="button" onClick={() => handleMainCategoryClick(category)}>
                            <img className="categoryIcon" src={category.mainInfo.icon} alt="..." />
                            <div className="mainCategoryName">{category.mainInfo.name}</div>
                        </div>
                    ))}

                    <Pagination className="paging">{items}</Pagination>
                </div>
                <div className="subCategories">
                    <div className="categoryTitleContainer">
                        <div className="categoryTitle">Sub-Categories</div>
                        <div className="categoryNewButton" onClick={() => setShowOverlay3(true)} type="button"> + </div>
                        <div className="categoryEditButton" onClick={() => setShowOverlay4(true)} type="button">Edit</div>
                        <div className="categoryDeleteButton" type="button" onClick={() => setShowOverlayDelete(true)}>
                            Delete
                        </div>
                    </div>
                    <hr style={{ background: "#252525", height: "2px", margin: "0px" }} />
                    {selectedMainCategory !== null && (
                        <div className="subCategoryContainerWrapper">
                            {currentSubItems.map((subCategory) => (
                                <div onClick={() => selectSubCategorey(subCategory)} key={subCategory.id} className={`subCategoryContainer ${selectedSubCategory === subCategory.id ? 'selectedSubCategory' : ''}`}>
                                    <img className="categoryIcon" src={subCategory.icon} alt="..." />
                                    <div className="mainCategoryName">
                                        {subCategory.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/*<Pagination className="paging">{subcategoryPaginationItems}</Pagination> */}

                </div>
                {showOverlay3 && (
                    <>
                        <div className="overlayBackground" onClick={() => setShowOverlay3(false)}></div>
                        <div className="overlay">
                            <div className="createOverlay3">
                                <div className="createOverlayTitle" style={{ marginBottom: "35px" }}>Create Sub-Category:</div>
                                <div className="entry">
                                    <div className="entryTitle">Name:</div>
                                    <input className="entryInput" value={subNewName}
                                        onChange={handleSubNewNameChange}></input>
                                </div>
                                <div className="entry" style={{ marginBottom: "35px" }}>
                                    <div className="entryTitle">Upload:</div>
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        onChange={handleImageUpload}
                                        maxsize={1024 * 1024 * 10} // 10MB (in bytes)
                                    />                                    </div>
                                <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                    <div className="submitCreation" type="button" onClick={() => { handleSubNewImageSubmit(); setShowOverlay3(false); }} > Submit </div>
                                </div>
                            </div>
                            <CloseIcon className="overlayClose" onClick={() => setShowOverlay3(false)} type="button" />
                        </div>
                    </>
                )}

                {showOverlay4 && (
                    <>
                        <div className="overlayBackground" onClick={() => setShowOverlay4(false)}></div>
                        <div className="overlay">
                            <div className="createOverlay3">
                                <div className="createOverlayTitle" style={{ marginBottom: "35px" }}>Edit Sub Category:</div>
                                <div className="entry">
                                    <div className="entryTitle">Name:</div>
                                    <input className="entryInput" value={subname}
                                        onChange={handleSubNameChange}></input>
                                </div>
                                <div className="entry" style={{ marginBottom: "35px" }}>
                                    <div className="entryTitle">Upload:</div>
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png"
                                        onChange={handleImageUpload}
                                        maxsize={1024 * 1024 * 10} // 10MB (in bytes)
                                    />

                                </div>
                                <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                    <div className="submitCreation" type="button" onClick={() => { handleSubImageSubmit(); setShowOverlay4(false); }}> Submit </div>
                                </div>
                            </div>
                            <CloseIcon className="overlayClose" onClick={() => setShowOverlay4(false)} type="button" />
                        </div>
                    </>
                )}

                {showOverlayDelete && (
                    <>
                        <div className="overlayBackground" onClick={() => setShowOverlayDelete(false)}></div>
                        <div className="overlayDelete">
                            <div className="createOverlayDelete">
                                <CloseIcon onClick={() => setShowOverlayDelete(false)} type="button" className="closeButton2" />
                                <div className="createOverlayTitleDelete"> <div> Delete Sub-category </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div className="deleteMessageWarning">Are you sure you want to delete? This process can't be undone.</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", height: "40px" }}>
                                    <div className="submitCreation" type="button" onClick={() => { setShowOverlayDelete(false); HandleDelSub() }} style={{ background: "#D43A2F", borderColor: "#A71F1F" }}> Delete </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

            </div>
            {statusToast && (
                <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                    <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                        <div >
                            {statusToast.status === 'success' ? 'Success' : 'Error'}
                        </div>
                    </Toast.Header>
                    <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                </Toast>
            )}
        </div>
    );
};

export default Categories;