// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
    margin: 20px 0 0 20px;
    width: 95%;
    height: fit-content;
}

.generalTitle {
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    margin-bottom: 20px;
}

.categoryButton {
    width: 90px;
    height: 25px;
    flex-shrink: 0;
    border-radius: 16px;
    opacity: 0.8;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: #030303;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 10px;
    margin-bottom: 5px;
}

.generalSubtitle{
    width: 33%;
    color: #FFF;
    height: fit-content;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.generalContent{
    width: 33%;
    color: #A0A0A0;
    height: fit-content;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/pages/users/AllUsers/middleSection/generalInformation.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".main {\n    margin: 20px 0 0 20px;\n    width: 95%;\n    height: fit-content;\n}\n\n.generalTitle {\n    color: #FFF;\n    font-family: Montserrat;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.categoryButton {\n    width: 90px;\n    height: 25px;\n    flex-shrink: 0;\n    border-radius: 16px;\n    opacity: 0.8;\n    background: #FFF;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 15px;\n    color: #030303;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    margin-right: 10px;\n    margin-bottom: 5px;\n}\n\n.generalSubtitle{\n    width: 33%;\n    color: #FFF;\n    height: fit-content;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n}\n\n.generalContent{\n    width: 33%;\n    color: #A0A0A0;\n    height: fit-content;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
