import "../products/details.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from 'react-router-dom';
import { fetchproductDetails, updateStautsButtom, fetchCategories, updateItemCategories } from "../global/axios";
import { useEffect, useState, useCallback } from 'react';
import { Spinner } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import { Modal, Button, Form } from "react-bootstrap";





const ProductDetails = () => {



    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [categoriesData, setCategoriesData] = useState(null);
    const [productDetails, setProductDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [statusToast, setStatusToast] = useState(null);
    const { productId } = useParams();
    const navigate = useNavigate();


    const fetchData = useCallback(async () => {
        try {
            const response = await fetchproductDetails(productId);
            const data = await fetchCategories()
            setCategoriesData(data.data);
            setProductDetails(response);
            console.log(response);
        } catch (error) {
            console.error('Error fetching product details:', error);
            setStatusToast({ status: 'error', message: 'Error fetching product details' });
        }
    }, [productId]);


    



    const updateCategories = (mainCategory, subCategory) => {
        console.log('Update Categories:', mainCategory, subCategory);
        updateProductDetails({
            id: productDetails.id,
            mainCategory: mainCategory,
            subCategory: subCategory,
        });
    };


    const updateProductDetails = async (newDetails) => {
        try {
            setLoading(true);
            console.log('Updating product categories:', newDetails);
            const updateResponse = await updateItemCategories({itemId: newDetails.id,mainCategoryId: newDetails.mainCategory,subCategoryId: newDetails.subCategory});
            const updatedResult = await fetchproductDetails(productId);
            setProductDetails(updatedResult);
            setLoading(false);
            setStatusToast({ status: 'success', message: 'Product Categories Updated Successfully' });
        }
        catch (error) {
            console.error('Error updating product categories:', error);
            setLoading(false);
            setStatusToast({ status: 'error', message: 'Error Updating Product Categories' });
        }
    };



    

    useEffect(() => {
        fetchData().then(() => {
            setLoading(false);
        })
            .catch(error => {
                setLoading(false);
            });
    }, [fetchData]);


    const EditCategoriesModal = ({ show, handleClose, productDetails, updateCategories }) => {

        const [selectedMainCategoryObject, setSelectedMainCategoryObject] = useState(null);
        const [selectedMainCategory, setSelectedMainCategory] = useState(null);
        const [selectedSubCategory, setSelectedSubCategory] = useState(null);



        useEffect(() => {
            if (categoriesData && productDetails) {
                const matchingCategory = categoriesData.find(
                    (category) => category.mainInfo.name === productDetails.mainCategory
                );
                setSelectedMainCategoryObject(matchingCategory || null);
                setSelectedMainCategory(matchingCategory?.mainInfo?.id || null);
                setSelectedSubCategory(
                    matchingCategory?.subCategories?.find(
                        (subCategory) => subCategory.name === productDetails.subCategory
                    )?.id || null
                );
            }
        }, [categoriesData, productDetails]);
        

        const handleMainCategoryChange = (category) => {
            setSelectedMainCategory(category.mainInfo.id);
            setSelectedMainCategoryObject(category)
            setSelectedSubCategory(null); // Reset subcategory when main category changes
        };


        const handleSubCategoryChange = (subCategoryId) => {
            setSelectedSubCategory(subCategoryId);
        };

        const handleSave = () => {
            updateCategories(selectedMainCategory, selectedSubCategory);
            handleClose();
        };

        return (
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Categories</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form>
                        <Form.Group controlId="formMainCategory" >
                            <Form.Label>Main Categories</Form.Label>
                            <div className="categories-list" style={{ display: "flex", flexWrap: "wrap" }}>
                                {categoriesData?.map((category) => (
                                    <Form.Check
                                        key={category.mainInfo.id}
                                        type="radio"
                                        label={category.mainInfo.name}
                                        checked={selectedMainCategory === category.mainInfo.id}
                                        onChange={() => handleMainCategoryChange(category)}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formSubCategory" >
                            <Form.Label>Sub Categories</Form.Label>
                            <div className="categories-list" style={{ display: "flex", flexWrap: "wrap" }}>
                                {categoriesData?.find((category) => category.mainInfo.id === selectedMainCategory)?.subCategories?.map((subCategory) => (
                                    <Form.Check
                                        key={subCategory.id}
                                        type="radio"
                                        label={subCategory.name}
                                        checked={selectedSubCategory === subCategory.id}
                                        name={`subCategory-${subCategory.id}`}
                                        onChange={() => handleSubCategoryChange(subCategory.id)}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    const HandleUserProfile = (ownerId) => {
        // window.location.href = `/users/${userId}`;
        navigate(`/users/${ownerId}`);

    };
    const HandleStauts = async (productid, status) => {
        try {
            setLoading(true);
            const newStatus = status === 'ACTIVE' ? 'SUSPENDED' : 'ACTIVE';

            const paylaod = {
                "itemId": productid,
                "status": newStatus

            };
            const res = await updateStautsButtom(paylaod);
            console.log(res);
            const updatedResult = await fetchproductDetails(productid);
            setProductDetails(updatedResult);
            setTimeout(async () => {
                setLoading(false);
                if (newStatus === 'ACTIVE') {
                    setStatusToast({ status: 'success', message: 'Product Activated successfully!' });
                } else if (newStatus === 'SUSPENDED') {
                    setStatusToast({ status: 'success', message: 'Product Suspended successfully!' });
                }

            }, 500);
        } catch (error) {
            console.error('Error updating product status:', error);
            setLoading(false);
            setStatusToast({ status: 'error', message: 'Error Updating Product Status' });
        }
    };

    if (!productId || productDetails === null || loading) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }
    return (
        <div className="details">

            {/*Title*/}
            <div className="productTitle">
                <Link to="/products" style={{ textDecoration: "none" }}>
                    <div type="button" className="buttonReturn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path opacity="0.7" d="M12.1728 15.5999L3.27344 7.9999L12.1728 0.399902L12.7266 1.04744L4.58555 7.9999L12.7266 14.9524" fill="white" />
                        </svg>
                    </div>
                </Link>
                <div className="titleText"> #{productDetails.productId} </div>
                <div className={`${productDetails.status === 'ACTIVE' ? 'accountStatusActive' : 'accountStatusSuspended'}`}>
                    {productDetails.status}
                </div>
            </div>
            <div className="pMain">
                {/*product Image*/}
                <div className="pImage">
                    {/* Image */}
                    <div>
                        <div className="card" style={{ width: "270px", backgroundColor: "#252525", margin: "0px 0px 20px 15px" }}>
                            <img style={{ width: "85%", height: "230px", margin: "20px 0px 20px 20px" }} src={productDetails.images[0]} alt="..." />
                        </div>
                    </div>
                    {/* Buttons */}
                    <div type="button" className="buttonsUnderImage" onClick={() => HandleUserProfile(productDetails.ownerId)}>
                        View User Profile
                    </div>
                    <div type="button" className={`buttonsUnderImage ${productDetails.status === 'SUSPENDED' ? 'activeButtonDetails' : 'suspendButtonDetails'}`}
                        onClick={() => HandleStauts(productDetails.id, productDetails.status)}>
                        {productDetails.status === 'ACTIVE' ? 'Suspend' : 'Activate'}
                    </div>

                </div>

                {/*product Description*/}

                <div className="pDetail">
                    {/*Three boxes*/}
                    <div className="pButtons">
                        <div className="productBox">
                            <div className="productboxContent" >

                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 39 40" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.88644 19.3111L20.3112 3.88644C20.842 3.35565 21.7026 3.35565 22.2334 3.88646L30.8027 12.4557C31.3335 12.9865 31.3335 13.8471 30.8027 14.3779L15.3779 29.8027C14.8471 30.3335 13.9865 30.3335 13.4557 29.8027L4.88644 21.2334C4.35565 20.7026 4.35565 19.842 4.88644 19.3111ZM2.4198 23.7001C0.526733 21.8069 0.526733 18.7376 2.4198 16.8446L17.8445 1.4198C19.7376 -0.473267 22.807 -0.473267 24.6999 1.4198L33.2694 9.98911C35.1623 11.8822 35.1623 14.9515 33.2694 16.8446L30.208 19.9058L37.0735 26.7713C39.6422 29.3399 39.6422 33.5046 37.0735 36.0733C34.5046 38.6419 30.3402 38.6419 27.7713 36.0733L20.906 29.2078L17.8445 32.2693C15.9515 34.1625 12.8822 34.1623 10.9891 32.2693L2.4198 23.7001ZM23.3725 26.7413L30.238 33.6066C31.4443 34.8132 33.4005 34.8132 34.6068 33.6066C35.8132 32.4002 35.8132 30.4443 34.6068 29.2379L27.7413 22.3725L23.3725 26.7413Z" fill="#030303" />
                                    <path d="M1.41406 39.1963H11.1091" stroke="#030303" strokeLinecap="round" />
                                </svg>

                                <div className="ProductboxTitle">{productDetails.bids}</div>
                            </div>
                            <div className="ProductBoxSubtitle">Bids on this item</div>
                        </div>

                        <div className="productBox">
                            <div className="productboxContent" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 38 34" fill="none">
                                    <path d="M19 5.24287L18.0449 6.16212C18.2948 6.42175 18.6396 6.56845 19 6.56845C19.3604 6.56845 19.7052 6.42175 19.9551 6.16212L19 5.24287ZM14.4507 27.9037C11.7719 25.792 8.84264 23.7297 6.51884 21.113C4.24048 18.5474 2.65116 15.5539 2.65116 11.6701H0C0 16.3812 1.96274 19.9753 4.53653 22.8734C7.06487 25.7204 10.2879 27.9979 12.8094 29.9858L14.4507 27.9037ZM2.65116 11.6701C2.65116 7.86858 4.79926 4.68063 7.73143 3.34033C10.58 2.03823 14.4076 2.38306 18.0449 6.16212L19.9551 4.32362C15.6392 -0.160393 10.6296 -0.899413 6.62925 0.929129C2.71251 2.71948 0 6.8767 0 11.6701H2.65116ZM12.8094 29.9858C13.7147 30.6995 14.6866 31.4605 15.6716 32.036C16.6562 32.6113 17.7798 33.079 19 33.079V30.4278C18.4528 30.4278 17.8089 30.2145 17.0092 29.747C16.2097 29.2798 15.3804 28.6367 14.4507 27.9037L12.8094 29.9858ZM25.1906 29.9858C27.7121 27.9979 30.9352 25.7204 33.4635 22.8734C36.0373 19.9753 38 16.3812 38 11.6701H35.3488C35.3488 15.5539 33.7596 18.5474 31.4811 21.113C29.1573 23.7297 26.2281 25.792 23.5494 27.9037L25.1906 29.9858ZM38 11.6701C38 6.8767 35.2875 2.71948 31.3707 0.929129C27.3704 -0.899413 22.3608 -0.160393 18.0449 4.32362L19.9551 6.16212C23.5923 2.38306 27.4199 2.03823 30.2685 3.34033C33.2007 4.68063 35.3488 7.86858 35.3488 11.6701H38ZM23.5494 27.9037C22.6195 28.6367 21.7903 29.2798 20.9908 29.747C20.1911 30.2145 19.5472 30.4278 19 30.4278V33.079C20.2202 33.079 21.3438 32.6113 22.3284 32.036C23.3134 31.4605 24.2852 30.6995 25.1906 29.9858L23.5494 27.9037Z" fill="#030303" />
                                </svg>
                                <div className="ProductboxTitle">{productDetails.likes}</div>
                            </div>
                            <div className="ProductBoxSubtitle">Likes on this item</div>
                        </div>

                        <div className="productBox">
                            <div className="productboxContent" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 42 28" fill="none">
                                    <path d="M21.0002 26.2778C25.081 26.2778 28.3891 22.9697 28.3891 18.8889C28.3891 14.8081 25.081 11.5 21.0002 11.5C16.9194 11.5 13.6113 14.8081 13.6113 18.8889C13.6113 22.9697 16.9194 26.2778 21.0002 26.2778Z" stroke="#030303" strokeWidth="3" />
                                    <path d="M40 18.8889C40 18.8889 37.8889 2 21 2C4.11111 2 2 18.8889 2 18.8889" stroke="#030303" strokeWidth="3" />
                                </svg>
                                <div className="ProductboxTitle" style={{ marginTop: "0px" }}>{productDetails.views}</div>
                            </div>
                            <div className="ProductBoxSubtitle">Views of this item</div>
                        </div>
                    </div>

                    {/*Tabs */}
                    <div className="pTabs">
                        <Tabs
                            defaultActiveKey="productInformation"
                            className="mb-3 mainTabs">

                            <Tab eventKey="productInformation" title="Product Information" style={{ backgroundColor: "#030303", height: "100%" }}>
                                <div style={{ marginLeft: "20px", background: "#030303" }}>
                                    <div className="detailTitle">Description</div>
                                    <div className="detailSubtitle">{productDetails.description}</div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "20px" }}>
                                            <div className="detailTitle">Price</div>
                                            <div className="detailSubtitle">{productDetails.price}</div>
                                        </div>
                                        <div style={{ marginRight: "20px" }}>
                                            <div className="detailTitle">Condition</div>
                                            <div className="detailSubtitle">{productDetails.condition}</div>
                                        </div>
                                    </div>
                                    <>
                                        <div className="detailTitle" onClick={handleShowModal} style={{ cursor: 'pointer' }}>Categories </div>
                                        <div style={{ display: "flex" }}>
                                            <div className="categoryButton" style={{ cursor: 'pointer' }} onClick={handleShowModal}> {productDetails.mainCategory} </div>
                                            <div className="categoryButton" style={{ cursor: 'pointer' }} onClick={handleShowModal}> {productDetails.subCategory} </div>
                                        </div>
                                        <EditCategoriesModal
                                            show={showModal}
                                            handleClose={handleCloseModal}
                                            productDetails={productDetails}
                                            updateCategories={updateCategories}
                                        />
                                    </>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <div className="detailTitle">Creation Date</div>
                                            <div className="detailSubtitle">{productDetails.createdAt}</div>
                                        </div>
                                        <div style={{ marginLeft: "50px" }}>
                                            <div className="detailTitle">Expiration Date</div>
                                            <div className="detailSubtitle">{productDetails.expiredAt}</div>
                                        </div>
                                        <div style={{ marginLeft: "50px" }}>
                                            <div className="detailTitle">Boost End Date</div>
                                            <div className="detailSubtitle">{productDetails.boostEndDate}</div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="gallery" title="Gallery">
                                <div style={{ display: "flex" }}>
                                    {productDetails.images.map((image, index) => (
                                        <div key={index} className="card" style={{ width: "175px", backgroundColor: "#252525", margin: "0px 20px 0px 20px", padding: "0px 0px 0px 0px" }}>
                                            <img style={{ width: "85%", height: "155px", margin: "12px 0px 12px 12px" }} src={image} alt={`${index + 1}`} />
                                        </div>
                                    ))}
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </div>
            {statusToast && (
                <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                    <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                        <div >
                            {statusToast.status === 'success' ? 'Success' : 'Error'}
                        </div>
                    </Toast.Header>
                    <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                </Toast>
            )}
        </div>
    );
};

export default ProductDetails;