import Header from "../../global/Header";
import "../UserDashboard/userDashboard.css";
import { useEffect, useState, useCallback } from "react";
import { fetchUserStatistics, fetchUserdata } from "../../global/axios";
import { ProgressBar, Spinner, Pagination, Toast } from "react-bootstrap";
import { handleStauts } from "../../global/axios";
import { Link } from "react-router-dom";
import Eye from "../../products/group.png";
import { useNavigate } from 'react-router-dom';


const UserDashboard = () => {
    // Function to fetch user statistics
    const [userStats, setUserStats] = useState(null);
    const [userdata, setuserdata] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [statusToast, setStatusToast] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userDataLoading, setUserDataLoading] = useState(true);
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            setUserDataLoading(true);
            const data = await fetchUserStatistics();
            setUserStats(data);
            const user = await fetchUserdata({ pageNumber: currentPage, size: itemsPerPage });
            setuserdata(user.data);
            setTotalPages(user.totalPages);
        } catch (error) {
            console.error("Error fetching user statistics:", error);
            setStatusToast({ status: 'error', message: 'Error fetching user data' });
        } finally {
            setLoading(false);
            setUserDataLoading(false);
        }
    }, [currentPage, itemsPerPage]);

    useEffect(() => {
        fetchData(); 
    }, [currentPage]);

    const handleEyeButtonClick = (userId) => {
       //window.location.href = `/users/${userId}`;
        navigate(`/users/${userId}`);

    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    let items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                {number}
            </Pagination.Item>
        );
    }


    const handleStatusChange = async (userId, currentStatus) => {
        try {
            setLoading(true);
            const capitalizedStatus = currentStatus.toUpperCase();
            let stat;
            if (capitalizedStatus === "ACTIVE") {
                stat = "SUSPENDED";
            } else {
                stat = "ACTIVE";
            }
            await handleStauts(userId, stat);
            await fetchData();
            setLoading(false);
            // Add a success toast message here
            setStatusToast({ status: 'success', message: `User's status changed to  ${stat.toLowerCase()} successfully!` });
        } catch (error) {
            console.error('Error changing user status:', error);
            setStatusToast({ status: 'error', message: 'Error changing user status' });
            setLoading(false);
        }
    };
    const getStatusColorClass = (status) => {
        switch (status) {
            case "Active":
                return "accountStatusActive";
            case "Suspended":
                return "accountStatusSuspended";
            case "Pending":
                return "accountStatusPending";
            case "In Active":
                return "accountStatusInactive";
            default:
                return "";
        }
    };

    // Helper function to determine the action button class based on the user status
    const getActionButtonClass = (status) => {
        switch (status) {
            case "Active":
                return "suspendButton";
            case "Suspended":
                return "activateButton";
            case "In Active":
                return "activateButton";
            case "Pending":
                return "activateButton";
            default:
                return "";
        }
    };

        if (userStats === null || userdata === null || loading) {
        return <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%", alignItems: "center" }}>
            <Spinner animation="border" variant="light" />
        </div>;
    }


    return (
        <div style={{ width: "88%" }}>
            <Header title={"Users"} />
            {/* Boxes */}
            <div className="boxes">
                <div className="box" style={{ background: "#B9E805", border: "#A3C913" }}>
                    <div className="boxTitle">{userStats.totalActiveUsers}</div>
                    <div className="boxSubtitle">Active Users</div>
                </div>
                <div className="box" style={{ background: "#FFCF23", border: "#E2B820" }}>
                    <div className="boxTitle">{userStats.totalInactiveUsers}</div>
                    <div className="boxSubtitle">Inactive Users</div>
                </div>
                <div className="box" style={{ background: "#D43A2F", border: "#A71F1F" }}>
                    <div className="boxTitle" style={{ color: "white" }}>
                        {userStats.totalSuspendedUsers}
                    </div>
                    <div className="boxSubtitle" style={{ color: "white" }}>
                        Suspended Users
                    </div>
                </div>
                <div className="box" style={{ background: "#9A9A9A", border: "#A9A9A9" }}>
                    <div className="boxTitle" style={{ color: "white" }}>
                        {userStats.totalDeletedUsers}
                    </div>
                    <div className="boxSubtitle" style={{ color: "white" }}>
                        Deleted Users
                    </div>
                </div>
            </div>
            {/* Title and button */}
            <div style={{ width: "100%", display: "flex", alignItems: "center", marginTop: "40px", marginBottom: "10px" }}>
                <div className="tableTitle">Recently Added Users</div>
                <Link to="/users" style={{ textDecoration: "none" }}>
                    <button className="userButton" style={{ background: "#030303", borderRadius: 3, border: "1px #030303 solid" }}>
                        View All
                    </button>
                </Link>
            </div>
            
            <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "70%", marginRight: "20px" }}>
                    <table style={{ border: "#252525 2px solid", width:"99%", margin:"20px 0px 0px 20px" }}>
                        <thead className="tableHeading" style={{ height: "55px" }}>
                            <tr>
                                <th className="interestTableHead2" style={{ paddingLeft: "22px"}}>
                                    ID
                                </th>
                                <th className="interestTableHead" >
                                    Name
                                </th>
                                <th className="interestTableHead" >
                                    Account Status
                                </th>
                                <th className="interestTableHead" >
                                    Date Added
                                </th>
                                <th className="interestTableHead" >
                                    Registration Method
                                </th>
                                <th className="interestTableHead" style={{width:"150px"}} ></th>
                            </tr>
                        </thead>
                        <tbody>
                            {userDataLoading ? (
                                <tr>
                                    <td colSpan="6" style={{ backgroundColor: "#121212", textAlign: "center", padding: "20px" }}>
                                        <div className="spinner-border text-light" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </td>
                                </tr>
                            ) : ( userdata.map((user) => (
                                <tr key={user.id} style={{height:"50px",border: "#252525 2px solid"}} >
                                    <td style={{ backgroundColor: "#121212", color: "#FFFFFF", paddingLeft: "15px", fontSize: "11px", height:"50px" }}>
                                        {user.id}
                                    </td>
                                    <td style={{ backgroundColor: "#121212", color: "#FFFFFF", height:"50px" }}>
                                        {user.name}
                                    </td>
                                    <td style={{ backgroundColor: "#121212", color: "#FFFFFF", height:"50px" }}>
                                        <div className={getStatusColorClass(user.status)}>
                                            {user.status}
                                        </div>
                                    </td>
                                    <td style={{ backgroundColor: "#121212", color: "#FFFFFF", height:"50px" }}>
                                        {user.joinedAt}
                                    </td>
                                    <td style={{ backgroundColor: "#121212", color: "#FFFFFF", height:"50px" }}>
                                        {user.registerMethod}
                                    </td>
                                    <td style={{ backgroundColor: "#121212", color: "#FFFFFF", width: "150px", display: "flex", height:"50px" }}>
                                        <div type="button" className={getActionButtonClass(user.status)}
                                            onClick={() => handleStatusChange(user.id, user.status)}>
                                            {user.status === "Active" ? "Suspend" : "Activate"}
                                        </div>
                                        <div style={{ width: "20px", height: "20px" }}>
                                            <img
                                                src={Eye}
                                                alt="eye"
                                                style={{ width: "20px", height: "20px", margin: "0px 0 0px 10px" }}
                                                type="button"
                                                onClick={() => handleEyeButtonClick(user.id)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </table>
                    <div>
                        <Pagination className="paging">{items}</Pagination>
                    </div>

                </div>
                <div style={{ height: "300px" }}>
                    <div className="Charts">
                        <div className="chart">
                            <div className="chartTitle">Registration Methods</div>
                            <div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">Normal</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar
                                            className="progressBar"
                                            now={userStats.normalRegistersPercentage}
                                            max={100}
                                        />
                                    </div>
                                    <div className="barValue">
                                        {userStats.normalRegistersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">Apple</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar
                                            className="progressBar"
                                            now={userStats.appleRegistersPercentage}
                                            max={100}
                                        />
                                    </div>
                                    <div className="barValue">
                                        {userStats.appleRegistersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">Google</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar
                                            className="progressBar"
                                            now={userStats.googleRegistersPercentage}
                                            max={100}
                                        />
                                    </div>
                                    <div className="barValue">
                                        {userStats.googleRegistersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel" style={{ marginBottom: "15px" }}>Facebook</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" style={{ marginBottom: "15px" }} now={userStats.facebookRegistersPercentage} max={100}/>
                                    </div>
                                    <div className="barValue" style={{ marginBottom: "15px" }}>
                                        {userStats.facebookRegistersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "180px" }}>
                        <div className="chart">
                            <div className="chartTitle">Account Status</div>
                            <div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">Active </div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={userStats.activeUsersPercentage} max={100} />
                                    </div>
                                    <div className="barValue">
                                        {userStats.activeUsersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">InActive</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={userStats.inActiveUsersPercentage}  max={100} />
                                    </div>
                                    <div className="barValue">
                                        {userStats.inActiveUsersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">suspended</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={userStats.suspendedUsersPercentage} max={100} />
                                    </div>
                                    <div className="barValue">
                                        {userStats.suspendedUsersPercentage.toFixed(2)}%
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="barLabel">Deleted</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar className="progressBar" now={userStats.deletedUsersPercentage} max={100} />
                                    </div>
                                    <div className="barValue">
                                        {userStats.deletedUsersPercentage.toFixed(2)}%
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {statusToast && (
                    <Toast onClose={() => setStatusToast(null)} autohide className="toastMessage">
                        <Toast.Header className={`toastHeader ${statusToast.status === 'success' ? 'text-success' : 'text-danger'}`}>
                            <div >
                                {statusToast.status === 'success' ? 'Success' : 'Error'}
                            </div>
                        </Toast.Header>
                        <Toast.Body className="toastBody">{statusToast.message}</Toast.Body>
                    </Toast>
                )}
        </div>
    );
};

export default UserDashboard;
