import "../middleSection/generalInformation.css";


import { Modal, Button, Form } from "react-bootstrap";
import { useState } from "react";
import Toast from 'react-bootstrap/Toast';
import { fetchCategories, updateUserInterests } from "../../../global/axios";






const GeneralInfo = ({ user, refreshUserData, categoriesData, setStatusToast}) => {
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);



    const EditInterestsModal = ({ show, handleClose, user, updateInterests, categoriesData}) => {
        const [selectedCategories, setSelectedCategories] = useState(user?.categories.map((x) => x.id) || []);

        // Handle category selection
        const handleCategoryChange = (subcategory, isChecked) => {
            console.log(subcategory);
            if (isChecked) {
                setSelectedCategories([...selectedCategories, subcategory]);
            } else {
                setSelectedCategories(selectedCategories.filter((cat) => cat !== subcategory));
            }
        };

        const handleSave = () => {
            // Map selected category names to their IDs
            const selectedCategoryIds = categoriesData
                .filter((category) => selectedCategories.includes(category.mainInfo.id)) // Include main categories
                .map((category) => category.mainInfo.id) // Map main categories to their IDs
                .concat(
                    categoriesData
                        .flatMap((category) => category.subCategories) // Include subcategories
                        .filter((subCategory) => selectedCategories.includes(subCategory.id)) // Filter by selected names
                        .map((subCategory) => subCategory.id) // Map subcategories to their IDs
                );
            
            updateInterests(selectedCategoryIds); // Send only IDs in the payload
            handleClose();
        };


        return (
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title style={{marginRight: "20px"}}>Edit Interests</Modal.Title>
                    {/* Select All Button */}
                    <Button style={{marginRight: "20px"}} size="sm" variant="outline-info" onClick={() => setSelectedCategories(categoriesData.flatMap((category) => [category.mainInfo.id, ...category.subCategories.map((subCategory) => subCategory.id)]))}>Select All</Button>
                    <Button  size="sm" variant="outline-info" onClick={() => setSelectedCategories([])}>Unselect All</Button>
                </Modal.Header>
                <Modal.Body>
                    {categoriesData.map((category) => {
                        const subCategories = category.subCategories || [];
                        return (
                            <div key={category.mainInfo.id} style={{ marginBottom: '20px' }}>
                                {/* Main Category */}
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    marginBottom: '10px',
                                    fontSize: '18px'
                                }}>
                                    <img
                                        src={category.mainInfo.icon}
                                        alt={category.mainInfo.name}
                                        style={{ width: '24px', marginRight: '8px' }}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label={category.mainInfo.name}
                                        checked={selectedCategories.includes(category.mainInfo.id)}
                                        onChange={(e) =>
                                            handleCategoryChange(category.mainInfo.id, e.target.checked)
                                        }

                                    />
                                </div>
                                {/* Subcategories */}
                                <div className="subcategories-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                    {subCategories.map((subCategory) => (
                                        <div key={subCategory.id} style={{ width: '150px' }}>
                                            <Form.Check
                                                type="checkbox"
                                                label={subCategory.name}
                                                checked={selectedCategories.includes(subCategory.id)}
                                                onChange={(e) =>
                                                    handleCategoryChange(subCategory.id, e.target.checked)
                                                }
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };





    const updateInterests = async (newInterests) => {
        try {
            setLoading(true);
            await updateUserInterests({ userId: user.id, interests: newInterests });
            // Fetch updated user data
            await refreshUserData(); // 
            setLoading(false);
            setStatusToast({ status: 'success', message: 'Interests Updated Successfully' });
        } catch (error) {
            console.error('Error updating interests:', error);
            setLoading(false);
            setStatusToast({ status: 'error', message: 'Error Updating Interests' });
        }
    };
    if (!user) {
        return <div style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>...</div>;
    }
    const {
        id,
        name,
        gender,
        dob,
        registerMethod,
        joinedAt,
        phone,
        email,
        meetUpLocation,
        bio,
        categories

    } = user;
    return (
        <div className="main" key={id}>
            {/* Personal Information */}
            <div className="generalTitle">Personal Information</div>

            <div style={{ display: "flex", width: "100%", height: "fit-content" }}>
                <div className="generalSubtitle">Name</div>
                <div className="generalSubtitle">Gender</div>
                <div className="generalSubtitle">Date of Birth</div>
            </div>

            <div style={{ display: "flex", width: "100%", height: "fit-content", marginTop: "5px" }}>
                <div className="generalContent">{name}</div>
                <div className="generalContent">{gender}</div>
                <div className="generalContent">{dob}</div>
            </div>

            <div style={{ display: "flex", width: "100%", height: "fit-content", marginTop: "18px" }}>
                <div className="generalSubtitle">Registered by</div>
                <div className="generalSubtitle">Joined at</div>
            </div>

            <div style={{ display: "flex", width: "100%", height: "fit-content", marginTop: "5px" }}>
                <div className="generalContent">{registerMethod}</div>
                <div className="generalContent">{joinedAt}</div>
            </div>

            {/* Contact Information */}
            <div className="generalTitle" style={{ marginTop: "30px" }}>Contact Information</div>

            <div style={{ display: "flex", width: "100%", height: "fit-content" }}>
                <div className="generalSubtitle">Mobile Number</div>
                <div className="generalSubtitle">Email</div>
            </div>

            <div style={{ display: "flex", width: "100%", height: "fit-content", marginTop: "5px" }}>
                <div className="generalContent">{phone}</div>
                <div className="generalContent">{email}</div>
            </div>

            {/* Location Information */}
            <div className="generalTitle" style={{ marginTop: "30px" }}>Meet-Up Location</div>

            <div style={{ display: "flex", width: "100%", height: "fit-content" }}>
                <div className="generalSubtitle">Address</div>
                <div className="generalSubtitle">City</div>
                <div className="generalSubtitle">Country</div>
            </div>



            <div style={{ display: "flex", width: "100%", height: "fit-content", marginTop: "5px" }}>
                <div className="generalContent">{meetUpLocation.address}</div>
                <div className="generalContent">{meetUpLocation.city}</div>
                <div className="generalContent">{meetUpLocation.country}</div>
            </div>


            {/* Interests */}
            <div className="generalTitle" style={{ marginTop: "30px" }}>Interests</div>

            <div style={{ display: "flex", flexWrap: "wrap", width: "100%", height: "fit-content" }}>
                {categories.map((category) => (
                    <div className="categoryButton" style={{ cursor: 'pointer' }} onClick={handleShowModal}> {category.name} </div>
                ))}
            </div>
            <EditInterestsModal
                show={showModal}
                handleClose={handleCloseModal}
                user={user}
                updateInterests={updateInterests}
                categoriesData={categoriesData} // Pass the array of categories here
            />

            {/* Account Notes */}
            <div className="generalTitle" style={{ marginTop: "30px" }}>Account Notes</div>
            <div className="generalContent" style={{ width: "100%", height: "fit-content" }}>{bio}</div>
            
        </div>

    );
};

export default GeneralInfo;